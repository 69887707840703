
<br>
<form>
  <div class="row">
    <div class="col-4 form-group">
      <label for="startdate">Date de début de période</label>
      <div class="input-group">
        <input class="form-control" placeholder="yyyy-mm-dd" name="startdate" [(ngModel)]="filterParams.startdate"
          ngbDatepicker #d1="ngbDatepicker" (dateSelect)="checkDate()" (click)="d1.toggle()">
        <div class="input-group-append">
          <button class="btn btn-grey btn-outline-secondary" (click)="d1.toggle()" type="button"><i
              class="fa fa-calendar"></i></button>
        </div>
      </div>
    </div>
    <div class="col-4 form-group">
      <label for="stopdate">Date de fin</label>
      <div class="input-group">
        <input class="form-control" placeholder="yyyy-mm-dd" name="stopdate" [(ngModel)]="filterParams.stopdate"
          ngbDatepicker #d2="ngbDatepicker" (dateSelect)="checkDate()" (click)="d2.toggle()">
        <div class="input-group-append">
          <button class="btn btn-grey btn-outline-secondary" (click)="d2.toggle()" type="button"><i
              class="fa fa-calendar"></i></button>
        </div>
      </div>
    </div>
    <div class="col-4 form-group">
      <button class="btn" style="margin-top: 32px;"
      [ngClass]="{'btn-success blink':dateChanged, 'btn-info': !dateChanged}"
      (click)="applyFilters()">Rechercher</button>
    </div>
  </div>

</form>
<br>

<div class="clearfix">
  <form class="inline">
    <button type="button" (click)="Export('TEXT')" class="ml-2 h-100 btn btn-outline-secondary btn-sm">Copy</button>
    <button type="button" (click)="Export('CSV')" class="ml-2 h-100 btn btn-outline-secondary btn-sm">CSV</button>
    <button type="button" (click)="Export('EXCEL')" class="ml-2 h-100 btn btn-outline-secondary btn-sm">Excel</button>
    <button type="button" (click)="Export('PRINT')" class="ml-2 h-100 btn btn-outline-secondary btn-sm">Print</button>

    <div class="form-inline ml-auto form-group">
      Recherche: <input class="form-control ml-2" type="text" name="searchTerm"
        [(ngModel)]="pagingService.searchTerm" />
    </div>
  </form>


  <table id="selectedColumn" class="table-bordered table table-hover table-sm table-striped" cellspacing="0"
    width="100%">
    <thead>
      <tr>
        <th class="th" sortable="id" (sort)="onSort($event)">ID</th>
        <th class="th" sortable="name" (sort)="onSort($event)">Intitulé</th>
        <th class="collapsible th" sortable="stage" (sort)="onSort($event)">Type</th>
        <th class="collapsible th" sortable="date_debut" (sort)="onSort($event)">Date début</th>
        <th class="collapsible th" sortable="dates" (sort)="onSort($event)">Dates</th>
        <th class="collapsible th" sortable="nbparticipants" (sort)="onSort($event)">#inscrits</th>
        <th class="th" sortable="nbreponses" (sort)="onSort($event)">#réponses</th>
        <th class="th" sortable="nbyes" (sort)="onSort($event)">#OUI</th>
        <th class="th" sortable="nbno" (sort)="onSort($event)">#NON</th>
        <th class="th"></th>
      </tr>
    </thead>
    @if (initialized) {
    <tbody>
      @for (f of formations$ | async; track f) {
      <tr>
        <td>{{f.id}}</td>
        <td>
          <ngb-highlight [result]="f.name" [term]="pagingService.searchTerm"></ngb-highlight>
        </td>
        <td class="collapsible">
            <ngb-highlight [result]="f.stage" [term]="pagingService.searchTerm">
            </ngb-highlight>
          </td>
        <td class="collapsible">
            <ngb-highlight [result]="f.date_debut" [term]="pagingService.searchTerm">
            </ngb-highlight>
          </td>
          <td class="collapsible">
              <ngb-highlight [result]="f.dates" [term]="pagingService.searchTerm">
              </ngb-highlight>
            </td>
        <td class="collapsible">
          <ngb-highlight [result]="f.nbparticipants.toString()" [term]="pagingService.searchTerm">
          </ngb-highlight>
        </td>
        <td class="collapsible">
          <ngb-highlight [result]="f.nbreponses.toString()" [term]="pagingService.searchTerm">
          </ngb-highlight>
        </td>
        <td class="collapsible">
          <ngb-highlight [result]="f.nbyes.toString()" [term]="pagingService.searchTerm">
          </ngb-highlight>
        </td>
        <td class="collapsible">
            <ngb-highlight [result]="f.nbno.toString()" [term]="pagingService.searchTerm">
            </ngb-highlight>
          </td>
        <td>
          <div class="inline">
            <button type="button" title="Voir les détails" (click)="selectFormation(f)"
              class="mr-2 btn btn-primary btn-sm w30"><i class="fa fa-list"></i></button>
          </div>
        </td>
      </tr>
      }
    </tbody>
    }
  </table>

  <div class="inline">
    <div class="">
      Total: {{(total$ | async)}}
    </div>
    <div class="ml-auto">
      <ngb-pagination class="d-flex justify-content-end" [collectionSize]="total$ | async" [(page)]="pagingService.page"
        [boundaryLinks]="true" [rotate]="true" [directionLinks]="true" [pageSize]="pagingService.pageSize" [maxSize]="3"
        name="page">
      </ngb-pagination>
    </div>
  </div>

</div>

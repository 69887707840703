import { Component, OnInit, Input, EventEmitter, Output, Injectable } from '@angular/core';
import { IAdeFormation } from 'src/app/interfaces/ade';

import * as R from 'ramda';
import moment from 'moment';
import { NgbDatepickerI18n, NgbDateStruct, NgbDateAdapter, NgbDateNativeAdapter, NgbPagination, NgbHighlight, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { AllianceDistributionService } from 'src/app/providers/alliance-distribution.service';
import { ToastrService } from 'ngx-toastr';
import { CommonModule, TranslationWidth } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SpinnerComponent } from '../spinner/spinner.component';

const I18N_VALUES = {
  fr: {
    weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
    months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
  }
  // other languages you would support
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable()
export class I18n {
  language = 'fr';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  // tslint:disable-next-line:variable-name
  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    return this.getWeekdayShortName(weekday);
}
}

@Component({
  selector: 'app-ade-edit',
  templateUrl: './ade-edit.component.html',
  styleUrls: ['./ade-edit.component.scss'],
  standalone: true,
  imports: [FormsModule, CommonModule, SpinnerComponent, NgbDatepickerModule],
  providers: [I18n, { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter} ,  { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class AdeEditComponent implements OnInit {

  @Input() client = null;
  @Input() selectedFormation: IAdeFormation = null;
  @Output() formation: EventEmitter<any> = new EventEmitter<any>();

  datedebut = null;
  origFormation: IAdeFormation;
  isSpinnerVisible = false;


  constructor(
    private adeService: AllianceDistributionService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    console.log('ade edit');
    this.datedebut = new Date(moment(this.selectedFormation.date_debut).format('YYYY-MM-DD')),
    this.origFormation = R.clone(this.selectedFormation);
  }

  cancel() {
    this.formation.emit(this.origFormation);
  }

  modify() {
    this.isSpinnerVisible = true;
    this.selectedFormation.date_debut = this.datedebut;
    console.log('update stage', this.selectedFormation);
    this.adeService.UpdateAdeStage(this.client.id, this.selectedFormation).subscribe( (response: any) => {
      if (response.success) {
        this.toastr.success('Le stage a été modifié', '');
        this.formation.emit(this.selectedFormation);
      } else {
        this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
      }
      this.isSpinnerVisible = false;
    });
  }

}

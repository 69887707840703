import { ViewChildren, QueryList, Component, OnInit, AfterViewInit } from '@angular/core';

import { Observable, of } from 'rxjs';
import { SortEvent, NgbdSortableHeader } from 'src/app/directives/sortable.directive';

import { IUser, User } from '../../interfaces/user';
import { PagingService } from '../../providers/paging.service';
import { UserService } from 'src/app/providers/user.service';
import { Router } from '@angular/router';

import * as R from 'ramda';
import { ExportDataService } from 'src/app/providers/export-data.service';
import { ToastrService } from 'ngx-toastr';
import { NgbHighlight, NgbModal, NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardService } from 'ngx-clipboard';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { MailService } from 'src/app/providers/mail.service';
import { CommonModule } from '@angular/common';
import { MenuHeaderComponent } from '../menu-header/menu-header.component';
import { SpinnerComponent } from '../spinner/spinner.component';
import { AsyncPipe } from '@angular/common';
import { ModalComponent } from 'src/app/modals/modal.component';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
  standalone: true,
  imports: [MenuHeaderComponent, FormsModule, NgbdSortableHeader, CommonModule, NgbHighlight, NgbPagination, ModalComponent, AsyncPipe, SpinnerComponent]
})

export class ClientComponent implements OnInit, AfterViewInit {

  isAdmin = this.authService.isAdmin;

  users$: Observable<IUser[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;

  users: IUser[] = [];
  selectedUser: IUser = null;
  usernameList = '';

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  page = 1;
  pageSize = 10;
  collectionSize = 1;
  searchFields = ['id', 'company', , 'entity', 'username', 'name', 'firstname', 'company', 'premium', 'remain_period', 'admin', 'disabled', 'option_postpaid', 'option_forfait'];

  showDeleteConfirm = false;
  isSpinnerVisible = false;

  pagingService = null;

  constructor(
    private userService: UserService,
    private authService: AuthenticationService,
    private exportDataService: ExportDataService,
    private toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal,
    private clipboardService: ClipboardService,
    private mailService: MailService,

  ) {
    this.pagingService = this.userService.pagingService;
    this.users$ = this.pagingService.rowsObs;
    this.total$ = this.pagingService.totalObs;
    this.loading$ = this.pagingService.loadingObs;
    this.pagingService.searchTerm = localStorage.getItem('lastUserSearched') ?? '';

    // set fields for filtering
    this.pagingService.fields = this.searchFields;

    this.loading$.subscribe(loading => {
      if (loading) {
        this.isSpinnerVisible = true;
      } else {
        this.isSpinnerVisible = false;
        this.usernameList = '';
        this.pagingService.allRows.forEach(c => this.usernameList += c.username + '; ');
      }
    });
  }

  ngOnInit(): void {

    this.pagingService.loadRows(this.userService.GetAll(this.authService.adminId));
    this.pagingService.page = 1;

    const selectedU = this.userService.getSelectedUser();
    if (selectedU) {
      this.selectClient(selectedU);
    }
  }

  ngAfterViewInit() {
  }

  setSearchTerm(term: string) {
    this.pagingService.searchTerm = term;
    localStorage.setItem('lastUserSearched', term);
  }

  pageChanged(event) {
    console.log('onPageChange', event);
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.pagingService.sortColumn = column;
    this.pagingService.sortDirection = direction;
  }

  selectClient(user) {
    console.log('select user', user);
    this.selectedUser = user;
    this.userService.selectedUser$.next(user);
  }

  Edit(user) {
    this.selectedUser = user;
    this.userService.selectedUser$.next(user);
    this.router.navigate(['/client/edition']);
    event.stopPropagation();
  }

  Export(kind) {
    console.log('export', kind);
    const records = [];
    const fields = ['id', 'username', 'name', 'firstname', 'company', 'entity', 'premium', 'admin'];
    for (const u of this.pagingService.filteredRows) {
      const user = R.pick(fields, u);
      records.push(user);
    }
    const header = {};
    fields.forEach(f => header[f] = f.replace(/[^a-z0-9]/gmi, '').toUpperCase());
    records.unshift(header);
    this.exportDataService.exportAs(kind, records, 'user');
  }

  Delete(user) {
    this.selectedUser = user;
    this.showDeleteConfirm = true;
    this.userService.selectedUser$.next(user);
    console.log('show delete confirm');
  }

  DeleteConfirm() {
    this.showDeleteConfirm = false;
    console.log('call delete api');

    this.userService.Delete(this.selectedUser).subscribe((response: any) => {
      if (response.success) {
        this.toastr.success('Le client a été supprimé');
        this.pagingService.loadRows(this.userService.GetAll(this.authService.adminId));
      } else {
        this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
      }
    });
  }

  createClient() {
    this.userService.selectedUser$.next(new User());
    this.router.navigate(['/client/edition']);
  }

  showCredits(user) {
    this.selectedUser = user;
    this.userService.selectedUser$.next(user);
    this.router.navigate(['/client/credit']);
  }

  showCampaigns(user) {
    this.selectedUser = user;
    this.userService.selectedUser$.next(user);
    this.router.navigate(['/campagne/status/encours']);
  }

  showLists(user) {
    this.selectedUser = user;
    this.userService.selectedUser$.next(user);
    this.router.navigate(['/contact/list']);
  }

  clipboardCopy() {
    this.clipboardService.copyFromContent(this.usernameList);
  }

  mailUserNameList() {
    this.isSpinnerVisible = true;
    const contactmail = {
      isAdmin: this.authService.isAdmin,
      id: 0,
      admin: this.authService.adminId,
      email_admin: '',
      name: '',
      company: '',
      username: this.authService.currentAdmin,
      subject: 'Liste des emails clients',
      message: this.usernameList
    };
    this.mailService.Send(contactmail)
      .subscribe((response) => {
        if (response.success) {
          this.toastr.success('Votre message a été envoyé', '');
        } else {
          this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
        }
        this.isSpinnerVisible = false;
      });
  }

}

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { HomePageComponent } from '../home-page/home-page.component';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { UserService } from 'src/app/providers/user.service';
import { RouterLink } from '@angular/router';
import { ModalComponent } from 'src/app/modals/modal.component';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.scss'],
  standalone: true,
  imports: [RouterLink, ModalComponent, CommonModule]
})
export class MenuHeaderComponent implements OnInit {

  @Output() changeTabEvent: EventEmitter<number> = new EventEmitter<number>();

  isAdmin = this.authService.isAdmin;
  watchdog = { idle: 3000,  // 30 min
    timeout: 60, // 60 sec
    countdown: 60,
    actif: false };

  client = {
    disabled: '0',
  };

  siteurl = 'isisms.fr';
  siteicon = './assets/img/logo_isi_sms_blanc.png';

  showLogoutConfirm = false;

  constructor(
    private homePageComponent: HomePageComponent,
    private authService: AuthenticationService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    console.log('header init');
    this.userService.selectedUser$.subscribe( user => {
      if (user) {
        this.client.disabled = user.disabled;
      }
    });
  }

  changeTab(choice) {
    this.changeTabEvent.emit(choice);
  }

  logout() {
    this.showLogoutConfirm = true;
  }

  logoutConfirm() {
    this.showLogoutConfirm = false;
    this.authService.logout();
    this.userService.clearSelectedUser();
  }


}


<aside class="modal" [class.is_active]="isActive">
  <div class="modal-overlay" (click)="handleOverlayClick()" *ngIf="hasOverlay"></div>
  <div class="modal-container" [class.modal-lg]="isLarge"  [class.modal-xl]="isXLarge" [class.my_class]="{'dialog-info': dialogType=='info', 'dialog-error': dialogType=='error'}">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{dialogTitle}}</h5>
        <div class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </div>
      </div>

      <div class="modal-content">
        <div class="p-2" [innerHTML]="dialogBody"></div>
      </div>

      <div class="modal-footer">
        <div class="btn" [class.my_class]="{'btn-outline-info': dialogType=='info', 'btn-outline-danger': dialogType=='error', 'btn-outline-primary': dialogType==''}" (click)="handleActionClick()">{{dialogAction}}</div>
      </div>
    </div>
  </div>
</aside>

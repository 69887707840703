import { ViewChildren, QueryList, Component, OnInit, OnDestroy, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SortEvent, NgbdSortableHeader } from 'src/app/directives/sortable.directive';

import { PagingService } from '../../providers/paging.service';
import { UserService } from 'src/app/providers/user.service';
import { Router, RouterModule } from '@angular/router';

import * as R from 'ramda';
import { IUser } from 'src/app/interfaces/user';
import { AuthenticationService } from 'src/app/providers/authentication.service';

import { ExportDataService } from 'src/app/providers/export-data.service';

import moment from 'moment';
import { FormsModule } from '@angular/forms';
import { AsyncPipe, CommonModule, NgClass, TranslationWidth } from '@angular/common';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { NgbHighlight, NgbPagination, NgbDatepickerI18n, NgbTimeStruct, NgbTimeAdapter, NgbDateStruct, NgbTimepicker, NgbDateAdapter, NgbDateNativeAdapter, NgbInputDatepicker  } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerComponent } from '../spinner/spinner.component';

/**
 * Example of a String Time adapter
 */
@Injectable()
export class NgbTimeStringAdapter extends NgbTimeAdapter<string> {

  fromModel(value: string): NgbTimeStruct {
    if (!value) {
      return null;
    }
    const time = moment(value);
    return {
      hour: time.hours(),
      minute: time.minutes(),
      second: time.seconds()
    };
  }

  toModel(time: NgbTimeStruct): string {
    if (!time) {
      return null;
    }
    const now = moment();
    now.hour(time.hour);
    now.minute(time.minute);
    now.second(0);
    return now.format('YYYY-MM-DD HH:mm:ss');
  }

  private pad(i: number): string {
    return i < 10 ? `0${i}` : `${i}`;
  }
}

export class NgbdTimepickerAdapter {
  time: '08:00:00';
}

const I18N_VALUES = {
  fr: {
    weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
    months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
  }
  // other languages you would support
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable()
export class I18n {
  language = 'fr';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  // tslint:disable-next-line:variable-name
  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    return this.getWeekdayShortName(weekday);
  }
}

@Component({
  selector: 'app-conso-per-admin',
  templateUrl: './conso-per-admin.component.html',
  styleUrls: ['./conso-per-admin.component.scss'],
  standalone: true,
  imports: [FormsModule, NgbdSortableHeader, NgClass, NgbHighlight, NgbPagination, AsyncPipe, SpinnerComponent, NgbInputDatepicker, CommonModule],
  providers: [I18n, { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }]
})
export class ConsoPerAdminComponent implements OnInit, OnDestroy {

  users$: Observable<IUser[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;

  selectedUser: IUser = null;
  client: IUser = null;
  userSubscribe = null;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  page = 1;
  pageSize = 10;
  collectionSize = 1;
  searchFields = ['id', 'admin', 'company', 'entity', 'username', 'username', 'premium'];
  isAdmin = this.authService.isAdmin;

  showResetModal = false;
  initialized = false;
  isSpinnerActive = false;

  period = {
    startdate: new Date(moment().format('YYYY-MM-01')),
    stopdate: new Date(moment().format('YYYY-MM-') + moment().endOf('month').format('DD'))
  };
  origPeriod = R.clone(this.period);

  selectedPeriod = {
    startdate: moment(this.period.startdate).format('YYYY-MM-DD'),
    stopdate: moment(this.period.stopdate).format('YYYY-MM-DD'),
    admin: this.authService.adminId,
  };

  conso: any;
  consoSubscribe = null;

  showAddCredit = false;
  creditType = 1;   // premium=1, lowcost=0
  credits = 0;

  activeTab : 0;
  dateChanged = false;

  // // provide your own if necessary
  // providers: [{provide: NgbDatepickerI18n, useClass: YourOwnDatepickerI18n}]

  constructor(
    public pagingService: PagingService<IUser>,
    private authService: AuthenticationService,
    private userService: UserService,
    private toastr: ToastrService,
    private exportDataService: ExportDataService,
    private router: Router,
  ) {
    // this.client = new User();
    // this.client.id = 0;
    this.users$ = pagingService.rowsObs;
    this.total$ = pagingService.totalObs;
    this.loading$ = pagingService.loadingObs;
    this.pagingService.searchTerm = '';
    // set fields for filtering
    this.pagingService.fields = this.searchFields;

    this.pagingService.loadRows(of([]));

    this.pagingService.loadRows(this.userService.GetCreditsPerAdmin(this.authService.adminId, this.selectedPeriod));

    this.loading$.subscribe(loading => {
      if (loading) {
        this.isSpinnerActive = true;
      } else {
        setTimeout(() => {
          this.isSpinnerActive = false;
        }, 500);
        this.initialized = true;
      }
    });
    this.pagingService.page = 1;
  }

  ngOnInit(): void {

    console.log('credits onInit', this.client);
    // if (!this.client) {
    //   this.router.navigate(['/']);
    // }

  }

  ngOnDestroy() {
    if (this.userSubscribe) {
      this.userSubscribe.unsubscribe();
    }
    if (this.consoSubscribe) {
      this.consoSubscribe.unsubscribe();
    }
  }

  pageChanged(event) {
    console.log('onPageChange', event);
  }

  selectTab(tab) {
    this.activeTab = tab;
  }

  checkDate() {
    this.dateChanged = this.origPeriod.startdate !== this.period.startdate ||
      this.origPeriod.stopdate !== this.period.stopdate;
  }


  selectPeriod() {

    this.isSpinnerActive = true;

    this.selectedPeriod = {
      ...this.selectedPeriod,
      startdate: moment(this.period.startdate).format('YYYY-MM-DD'),
      stopdate: moment(this.period.stopdate).format('YYYY-MM-DD'),
    };
    this.origPeriod = R.clone(this.period);
    this.dateChanged = false;

    this.pagingService.loadRows(this.userService.GetCreditsPerAdmin(this.authService.adminId, this.selectedPeriod));

    this.isSpinnerActive = false;

  }


  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.pagingService.sortColumn = column;
    this.pagingService.sortDirection = direction;
  }

  selectUser(user) {
    this.selectedUser = R.clone(user);
  }


  Export(kind) {
    console.log('export', kind);
    const records = [];
    const fields = this.searchFields;

    for (const u of this.pagingService.filteredRows) {
      const user = R.pick(fields, u);
      records.push(user);
    }
    const header = {};
    fields.forEach(f => header[f] = f.replace(/[^a-z0-9]/gmi, '').toUpperCase());
    records.unshift(header);
    this.exportDataService.exportAs(kind, records, 'credits_' + moment().format('YYYY-MM-DD') );
  }

  selectAndShowCampaigns(user) {
    this.userService.GetById(user.id).subscribe( (res: any) => {
      this.router.navigate(['/campagne/status/encours']);
    });
  }

  selectAndShowCredits(user) {
    this.userService.GetById(user.id).subscribe( (res: any) => {
      this.router.navigate(['/client/credit']);
    });
  }

  forfaitSendMail() {
    this.userService.SendForfaitMail(this.authService.adminId, this.authService.currentUser)
    .subscribe( (response: any) => {
      if (response.success) {
        this.toastr.success('Le mail a été envoyé', '');
      } else {
        this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
      }
    });
  }

}


import { Directive, HostListener, Input } from '@angular/core';


@Directive({
  selector: '[appPattern]',
  standalone: true
})

export class AppPatternDirective {

  // tslint:disable-next-line: no-input-rename
  @Input('appPattern') appPattern = '';

  constructor() {}

  @HostListener('input', ['$event']) onKeyUp(event) {
    if (event.target.value) {
      console.log('appPattern', this.appPattern );
      let value = event.target.value;
      if (this.appPattern && this.appPattern.length > 0) {
        const regex = new RegExp(this.appPattern, 'g');
        value = value.replace(regex, '');
      }
      event.target.value = value;
    }
  }
}

import { ViewChildren, QueryList, Component, OnInit, OnDestroy, ChangeDetectionStrategy  } from '@angular/core';
import { Observable } from 'rxjs';
import { SortEvent, NgbdSortableHeader } from 'src/app/directives/sortable.directive';

import { PagingService } from '../../providers/paging.service';
import { UserService } from 'src/app/providers/user.service';
import { Router } from '@angular/router';

import * as R from 'ramda';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { IUser } from 'src/app/interfaces/user';
import { AuthenticationService } from 'src/app/providers/authentication.service';

import { ExportDataService } from 'src/app/providers/export-data.service';

import moment from 'moment';
import { IConso } from 'src/app/interfaces/consos';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  selector: 'app-conso-recap',
  templateUrl: './conso-recap.component.html',
  styleUrls: ['./conso-recap.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [CommonModule, SpinnerComponent],
})
export class ConsoRecapComponent implements OnInit, OnDestroy {

  conso: IConso[] = null;
  consoSubscribe = null;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  page = 1;
  pageSize = 10;
  collectionSize = 1;
  searchFields = ['date', 'premiumcons', 'lowcostcons', 'total', 'premium', 'lowcost', 'forfait', 'forfaitC', 'postpaid', 'postpaidC' ];
  isAdmin = this.authService.isAdmin;

  showResetModal = false;
  initialized = false;
  isSpinnerVisible = false;


  constructor(
    private authService: AuthenticationService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private exportDataService: ExportDataService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    console.log('conso-recap');
    this.isSpinnerVisible = true;
    this.consoSubscribe = this.userService.GetCreditsRecap(this.authService.adminId).subscribe( (res: any) => {
      if (res.success) {
        const conso = [];
        for (const key in res.credits) {
          if (res.credits.hasOwnProperty(key)) {
            conso.push(res.credits[key]);
          }
        }
        this.conso = R.clone(conso);
        this.isSpinnerVisible = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.consoSubscribe) {
      this.consoSubscribe.unsubscribe();
    }
  }

  pageChanged(event) {
    console.log('onPageChange', event);
  }

  Export(kind) {
    console.log('export', kind);
    const records = [];
    const fields = ['date', 'premiumcons', 'total', 'premium', 'forfait', 'postpaid', 'postpaidC' ];

    for (const u of this.conso) {
      const user = R.pick(fields, u);
      records.push(user);
    }
    const header = {};
    fields.forEach(f => header[f] = f.replace(/[^a-z0-9]/gmi, '').toUpperCase());
    records.unshift(header);
    this.exportDataService.exportAs(kind, records, 'credits_' + moment().format('YYYY-MM-DD') );
  }

}

import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { UserService } from 'src/app/providers/user.service';

@Component({
  selector: 'app-client-select',
  templateUrl: './client-select.component.html',
  styleUrls: ['./client-select.component.scss'],
  standalone: true,
  imports: [RouterLink, CommonModule]
})
export class ClientSelectComponent implements OnInit {

  @Input() selectedUser = null;

  constructor(
    public authService: AuthenticationService,
    private userService: UserService
  ) { }

  ngOnInit() {
  }

}

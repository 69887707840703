import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { IUser } from 'src/app/interfaces/user';
import { UserService } from 'src/app/providers/user.service';
import { ToastrService } from 'ngx-toastr';
import { ContactListService } from 'src/app/providers/contact-list.service';

import moment from 'moment';
import * as R from 'ramda';
import { JsonPipe } from '@angular/common';
import { SpinnerComponent } from '../spinner/spinner.component';
import { ClientSelectComponent } from '../client-select/client-select.component';
import { CommonModule } from '@angular/common';
import { ImportFileComponent } from '../import-file/import-file.component';
import { FormsModule } from '@angular/forms';
import { ModalComponent } from 'src/app/modals/modal.component';
import { DialogComponent } from 'src/app/dialog/dialog.component';

@Component({
  selector: 'app-contact-list-upload',
  templateUrl: './contact-list-upload.component.html',
  styleUrls: ['./contact-list-upload.component.scss'],
  standalone: true,
  imports: [SpinnerComponent, ClientSelectComponent, CommonModule, ImportFileComponent, FormsModule, ModalComponent, DialogComponent, JsonPipe]

})
export class ContactListUploadComponent implements OnInit, OnDestroy {

  client: IUser;
  userSubscribe = null;
  activeTab = 0;
  showAddList = false;
  isSpinnerVisible = false;

  rawdata = '';
  parsedData = [];
  parsedDataLength = 0;
  fields = [];
  mobileField = { name: '', position: 0 };
  mobileCol = -1;
  listName = '';

  dialog = {
    show: false,
    isLarge: false,
    title: '',
    body: '',
    action: '',
    type: '',
  };


  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private contactListService: ContactListService,
    private router: Router,
  ) { }

  ngOnInit() {
    console.log('onInit');
    this.userSubscribe  = this.userService.selectedUser$.subscribe( user => {
      if (user) {
        this.client = R.clone(user);
      }
    });
    setTimeout(() => {
      if (!this.client) {
        this.toastr.warning('Sélectionner un client en premier', '');
        this.router.navigate(['/']);
      }
    }, 1000);

  }

  ngOnDestroy() {
    if (this.userSubscribe) {
      this.userSubscribe.unsubscribe();
    }
  }

  selectTab(tab) {
    this.activeTab = tab;
  }

  clearInput() {
    this.fields = [];
    this.mobileField.position = -1;
    this.rawdata = '';
    this.parsedData = [];
  }

  selectCol(mobileCol) {
    console.log('selectCol');

    this.mobileField.position = mobileCol;

    // uppercase all fields
    for (const key in this.fields) {
      if (this.fields.hasOwnProperty(key)) {
        this.fields[key] = this.fields[key].toUpperCase();
        if (parseInt(key, 10) === mobileCol && this.fields[key] === 'COL' + (mobileCol + 1)) {
            this.fields[key] = 'TELEPHONE';
        }
      }
    }
    this.listName = this.client.company + ' ' + moment().format('YYYY-MM-DD');
    this.parsedDataLength = this.parsedData.length;
    this.showAddList = true;
  }

  parseRaw() {

    console.log('parseRow');

    // The array we will return
    this.parsedData = [];

    try {
      // Pasted data split into rows
      const rows = this.rawdata.split(/[\n\f\r]/);
      rows.forEach((thisRow) => {
          const row = thisRow.trim();
          if (row !== '') {
              const cols = row.split('\t');
              // first field must be integer (LIP requirement)
              const isnum = /^\d+$/.test(cols[0]);
              if (!isnum) { cols.unshift(''); }

              // push result cols in parsedData
              this.parsedData.push(cols);
          }
      });

      if (this.parsedData.length > 0) {
        if (this.fields.length === 0 || this.fields.length !== this.parsedData[0].length) {
          this.fields = [];
          this.mobileField = { name: '', position: -1 };
          // the fields length doesnot match new datas -> rebuild array
          for (let index = 0; index < this.parsedData[0].length; index++) {
            if (this.mobileField.position === index) {
                this.fields.push('TELEPHONE');
            } else {
                this.fields.push('COL' + (index + 1));
            }
          }
        }
      }

    } catch (err) {
        console.log('error parsing as tabular data');
        console.log(err);
        return null;
    }
  }

  InsertContacts() {

        this.isSpinnerVisible = true;
        this.showAddList = false;
        this.mobileField = { name: 'TELEPHONE', position: this.mobileField.position };

        this.contactListService.Add(
          this.client.id,
          { name: this.listName,
            mobile: this.fields[this.mobileField.position],
            fields: this.fields }
        ).subscribe((response) => {
            if (response.success) {
              const listid = response.id;

              // now import all contact
              this.contactListService.ContactAdd(
                this.client.id,
                listid,
                { mobileField: this.mobileField,
                  data: this.parsedData
                }
                ).subscribe((response2: any) => {
                  if (response2.success) {

                    this.dialog = {
                      show: true,
                      isLarge: true,
                      title: 'Liste importée',
                      body: '<br>La liste a été créée avec l\'identifiant: ' + listid + '<br><br>' +
                        response2.count + ' contacts ajouté' + (response2.count > 1 ? 's' : '') + '<br>' +
                        response2.duplicated + ' dupliqué' + (response2.duplicated > 1 ? 's' : '') + '<br>' +
                        response2.invalid + ' contacts invalide' + (response2.invalid > 1 ? 's' : '') +
                        ' ou vide' + (response2.invalid > 1 ? 's' : ''),
                      action: 'OK',
                      type: 'info',
                    };

                  } else {
                    this.dialog = {
                      show: true,
                      isLarge: true,
                      title: 'L\'importation de la liste a échouée',
                      body: '<br>La liste a été créée avec l\'identifiant: ' + listid + '<br><br>'  +
                        'Erreur lors de l\'ajout des contacts:<br>' + response2.message,
                      action: 'Fermer',
                      type: 'error',
                    };
                  }
                  this.isSpinnerVisible = false;
                });
            } else {
              this.dialog = {
                show: true,
                isLarge: true,
                title: 'La création de la liste a échouée',
                body: response.message,
                action: 'Fermer',
                type: 'error',
              };
              this.isSpinnerVisible = false;
            }
        });
  }

  back() {
    console.log('back');
    this.router.navigate(['/contact/list']);
  }

  closeImport(listid) {
    console.log('list imported', listid);
    if (listid !== 0) {
      this.back();
    }
  }

}

<!-- <h3>Nouvelle annonce:</h3> -->

@if (newAnnonce) {
<form name="form" role="form" autocomplete="off" autocapitalize="off" autocorrect="off" #f="ngForm">

  <div class="form-group">
    <div class="row">
      <div class="col-4 form-group">
        @if (selectedAgence.code_agence === '...') {
        <div class="blink">Sélectionnez votre agence:</div>
        }
        <select [(ngModel)]="selectedAgence" name="code" class="agence" (ngModelChange)="selectAgence($event)">
          @for (agence of agences; track agence.id) {
          <option [ngValue]="agence" [selected]="selectedAgence===agence">
            {{agence.code_agence === '...' ? 'Toutes' : agence.code_agence + ' - ' + agence.name}}</option>
          }
        </select>
      </div>
    </div>
  </div>

  <div class="form-check col-12">
    <div class="row">
      <div class="col-sm-4 col-12">
        <label class="h5" for="date">Référence</label>
        <div class="input-group">
          <input type="text" class="form-control" id="refrence" name="refrence" [(ngModel)]="newAnnonce.reference"
            autocomplete="off" autocapitalize="off" placeholder="Référence" required>
        </div>
      </div>
      <div class="col-sm-8 col-6">
        <label class="h5" for="annonce">Contact agence</label>
        <div class="input-group">
          <input type="text" class="form-control" id="contact" name="contact" [(ngModel)]="newAnnonce.contact"
            autocomplete="off" autocapitalize="off" placeholder="votre Email ou contact agence" required>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12">
    <label for="smstext">Modéle du texte SMS envoyé</label>
    <ul>
      <li>Ajouter %reference% pour ajouter la référence de l'annonce</li>
      <li>Ajouter %url% pour le lien long de l'annonce</li>
    </ul>
    <textarea name="smstext" id="smstext" class="form-control" style="resize:vertical;margin-bottom:10px;" rows="5"
      [(ngModel)]="newAnnonce.smstext"></textarea>
  </div>


  <div class="col-12">
    <div class="form-group">
      <label class="control-label h5" for="reference">URL site web:</label>
      <div class="input-group">
        <input type="text" name="url" id="url" class="form-control" [(ngModel)]="newAnnonce.url" autocomplete="off"
          autocapitalize="off" autocorrect="off" placeholder="URL de la page WEB de l'annonce" required
          #urlRef="ngModel" />
        @if (urlRef.dirty && urlRef.errors?.required) {
        <div class="text-danger text">Champ obligatoire</div>
        }

        <div class="btn btn-success" (click)="loadPreviewAnnonce()">Vérifier</div>
      </div>
    </div>
  </div>
  <div class="d-flex form-actions">

    <button [disabled]="f.invalid || newAnnonce.listid == 0" class="btn btn-success"
      [ngClass]="{'btn-warning': f.invalid || newAnnonce.listid == 0, 'blink': !f.invalid && newAnnonce.listid != 0}"
      (click)="create()">Créer l'annonce</button>

  </div>
</form>
}
<br>
<br>

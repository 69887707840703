<app-menu-header></app-menu-header>

<div class="main-container">

    <div class="alert alert-well main-sms" >
        <div class="pull-right btn btn-info"  style="margin-top:-9px" ng-click="changeTab(tabs.client)">Selectionner</div>
        <div *ngIf="client?.id==0">Sélectionnez un client ...
        </div>
        <div *ngIf="client?.id>0">
            <div>
            Client sélectionné: <b>{{client?.company}} {{client?.firstname}} {{client?.name}}</b> ({{client?.id}})
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Crédits SMS: Premium <b>{{client?.premium}}</b>
            </div>
        </div>
    </div>

    <router-outlet></router-outlet>

</div>

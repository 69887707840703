import { Component, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  standalone: true,
  imports: []
})
export class DialogComponent implements OnInit {

  @Input() overlayDismiss = true;
  @Input() isActive = true;
  @Input() isLarge = false;
  @Input() isXLarge = false;

  @Input() dialogTitle = '';
  @Input() dialogBody = '';
  @Input() dialogAction = '';
  @Input() dialogType = '';

  hasOverlay = true;
  @Output() closeClick: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  constructor(
    public activeModal: NgbModal
  ) {}

  ngOnInit() {
    this.isActive = false;
  }

  openModal() {
    this.isActive = true;
  }

  closeModal() {
    this.isActive = false;
  }

  handleOverlayClick() {
    console.log('overlayClose');
    if (this.overlayDismiss) {
      this.closeModal();
      this.closeClick.emit(true);
    }
  }

  handleActionClick() {
    console.log('handleActionClick');
    this.closeModal();
    this.closeClick.emit(true);
  }

}


import { Component, OnInit, Output, EventEmitter, Input, QueryList, ViewChildren } from '@angular/core';
import { IUser } from 'src/app/interfaces/user';
import { PagingService } from 'src/app/providers/paging.service';
import { IModele } from 'src/app/interfaces/modele';
import { ExportDataService } from 'src/app/providers/export-data.service';
import { UserService } from 'src/app/providers/user.service';
import { MlpService } from 'src/app/providers/mlp.service';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { async, Observable, of } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from 'src/app/directives/sortable.directive';

import moment from 'moment';
import * as R from 'ramda';
import { IMlpCampaign} from 'src/app/interfaces/mlp';
import { NgbHighlight } from '@ng-bootstrap/ng-bootstrap';
import { AsyncPipe } from '@angular/common';
import { DatePipe } from '@angular/common';
import { NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-mlp-campagne',
  templateUrl: './mlp-campagne.component.html',
  styleUrls: ['./mlp-campagne.component.scss'],
  standalone: true,
  imports: [FormsModule, NgbdSortableHeader, NgbHighlight, NgbPagination, AsyncPipe, DatePipe, CommonModule]
})
export class MlpCampagneComponent implements OnInit {
  @Input() client: IUser = null;
  @Input() selectedCampaigns = [];
  @Input() commerciaux = [];
  @Input() chefsRegion = [];
  @Input() customers = [];
  @Input() filterParams: any = {};
  @Output() selectNim: EventEmitter<any> = new EventEmitter<any>();
  @Output() openPhoto: EventEmitter<any> = new EventEmitter<any>();

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  page = 1;
  pageSize = 10;
  collectionSize = 1;
  searchFields = ['id', 'campaignid', 'chef_region', 'commercial', 'telephone', 'nim', 'client', 'smsok', 'totalphotos', 'commentaires'];

  campaigns = [];


  campaigns$: Observable<IMlpCampaign[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  initialized = false;

  constructor(
    public pagingService: PagingService<IMlpCampaign>,
    private exportDataService: ExportDataService,
    private userService: UserService,
    private mlpService: MlpService,
    private authService: AuthenticationService,
    private spinner: NgxSpinnerService,
  ) {
    this.campaigns$ = pagingService.rowsObs;
    this.total$ = pagingService.totalObs;
    this.loading$ = pagingService.loadingObs;
    this.pagingService.searchTerm = '';
    // set fields for filtering
    this.pagingService.fields = this.searchFields;

    // load campaigns from
  }

  ngOnInit() {
    this.pagingService.loadRows(of([]));
    this.pagingService.loadRows(this.mlpService.GetAllCampaign(this.selectedCampaigns));
    this.loading$.subscribe(loading => {
      if (loading) {
        this.spinner.show();
      } else {
        this.spinner.hide();
        this.initialized = true;
      }
    });
    this.pagingService.page = 1;
  }

  pageChanged(event) {
    console.log('onPageChange', event);
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.pagingService.sortColumn = column;
    this.pagingService.sortDirection = direction;
  }


 async resizeDataURL(datas, wantedWidth, wantedHeight) {

    return new Promise((resolve, reject) => {

        // We create an image to receive the Data URI
        var img = document.createElement('img');

        // When the event "onload" is triggered we can resize the image.
        img.onload = () => {
                // We create a canvas and get its context.
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                // We resize the image with the canvas method drawImage();
                ctx.drawImage(img, 0, 0, wantedWidth, wantedHeight);
                const dataURI = canvas.toDataURL();
                resolve(dataURI);
            };

        // We put the Data URI in the image's src attribute
        img.src = datas;
    });
  }

  async getBase64ImageFromUrl(imageUrl) {
    try {
      var res = await fetch(imageUrl);
      var blob = await res.blob();

      return new Promise((resolve, reject) => {
        var reader  = new FileReader();
        reader.addEventListener("load",  async () => {
            const resized = await this.resizeDataURL(reader.result, 280, 250);
            resolve(resized);
        }, false);

        reader.onerror = () => {
          return reject(this);
        };
        reader.readAsDataURL(blob);
      })
    } catch (e) {
      console.log('error', e);
      return of(null);
    }
  }


  async Export(kind) {
    console.log('export', kind);
    this.spinner.show();

    const records = [];
    const images = [];
    const fields = ['id', 'campaignid', 'chef_region', 'commercial', 'telephone', 'nim', 'client', 'smsok', 'totalphotos', 'commentaires'];

    for (const r of this.pagingService.filteredRows) {
      const record = R.pick(fields, r);
      if (kind === 'EXCEL') {
        const image = [];
        if (r.photo1.length > 0) {
          const url = 'https://isisms.fr/mlp/' + r.photo1;
          image.push({rank: 1, url , image: await this.getBase64ImageFromUrl(url)});
        } else {
          image.push({rank: 1, url: null, image: null});
        }
        if (r.photo2.length > 0) {
          const url = 'https://isisms.fr/mlp/' + r.photo2;
          image.push({rank: 2, url , image: await this.getBase64ImageFromUrl(url)});
        } else {
          image.push({rank: 2, url: null, image: null});
        }
        if (r.photo3.length > 0) {
          const url = 'https://isisms.fr/mlp/' + r.photo3
          image.push({rank: 3, url , image: await this.getBase64ImageFromUrl(url)});
        } else {
          image.push({rank: 3, url: '', image: null});
        }
        images.push(image);
      }

      records.push(record);
    }
    const header = {};
    fields.forEach(f => header[f] = f.replace(/[^a-z0-9]/gmi, '').toUpperCase());
    records.unshift(header);
    await this.exportDataService.exportAs(kind, records, 'campagne_' + moment().format('YYYY-MM-DD'), images);
  }

  getChefRegion(cdrid) {
    return this.mlpService.getNameFromId(this.chefsRegion, cdrid);
  }

  getCommercial(commid) {
    return this.mlpService.getNameFromId(this.commerciaux, commid);
  }

  getCusto(nim) {
    const custo = this.customers.find( c => c.nim === nim);
    if (custo) {
      return custo.diffuseur;
    } else {
      return custo;
    }
  }

  showCusto(nim) {
    this.selectNim.emit(nim);
  }

  showPhoto(c) {
    this.openPhoto.emit(c);
  }

}



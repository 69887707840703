<div *ngIf="selectedCampaigns.length==0">
  <div class="w-100 alert alert-danger">Séléctionnez en premier une campagne dans la liste 'Toutes les campagnes'</div>
</div>

<br>
<div class="camp-info alert alert-well" *ngIf="selectedCampaigns.length>0">
  <h3>{{selectedCampaigns[0].name}}</h3>
  <p>{{selectedCampaigns[0].datecreation}}</p>
</div>
<div *ngIf="selectedCampaigns.length > 1" class="camp-info">
  <h3>Campagnes du {{filterParams.startdate| date: 'dd/MM/yyyy' }} au {{filterParams.stopdate | date: 'dd/MM/yyyy'}}</h3>
  <div>{{selectedCampaigns.length}} campagnes sélectionnées</div>
  <div *ngFor="let c of selectedCampaigns">
    {{c.id}} - {{c.name}}
  </div>
</div>
<br>

<div class="clearfix" *ngIf="selectedCampaigns.length>0">
  <form class="inline">
    <button type="button" (click)="Export('TEXT')" class="ml-2 h-100 btn btn-outline-secondary btn-sm">Copy</button>
    <button type="button" (click)="Export('CSV')" class="ml-2 h-100 btn btn-outline-secondary btn-sm">CSV</button>
    <button type="button" (click)="Export('EXCEL')" class="ml-2 h-100 btn btn-outline-secondary btn-sm">Excel</button>
    <button type="button" (click)="Export('PRINT')" class="ml-2 h-100 btn btn-outline-secondary btn-sm">Print</button>

    <div class="form-inline ml-auto form-group">
      Recherche: <input class="form-control ml-2" type="text" name="searchTerm"
        [(ngModel)]="pagingService.searchTerm" />
    </div>
  </form>

  <table id="selectedColumn" class="table-bordered table table-hover table-sm table-striped" cellspacing="0"
    width="100%">
    <thead>
      <tr>
        <th class="th" sortable="id" (sort)="onSort($event)">ID</th>
        <th class="th" sortable="campaignid" (sort)="onSort($event)">#Campagne</th>
        <th class="collapsible th" sortable="chef_region" (sort)="onSort($event)">ChefDeRégion</th>
        <th class="collapsible th" sortable="commercial" (sort)="onSort($event)">Commercial</th>
        <th class="collapsible th" sortable="telephone" (sort)="onSort($event)">Telephone</th>
        <th class="th" sortable="nim" (sort)="onSort($event)">NIM</th>
        <th class="collapsible th" sortable="client" (sort)="onSort($event)">Nom</th>
        <th class="collapsible th" sortable="smsok" (sort)="onSort($event)">SMS ok</th>
        <th class="collapsible th">#Photos</th>
        <th class="collapsible th" sortable="commentaires" (sort)="onSort($event)">Commentaires</th>
        <th class="th"></th>
      </tr>
    </thead>
    <tbody *ngIf="initialized">
      <tr *ngFor="let c of campaigns$ | async">
        <td>
          <ngb-highlight [result]="c.id" [term]="pagingService.searchTerm"></ngb-highlight>
        </td>
        <td>
          <ngb-highlight [result]="c.campaignid" [term]="pagingService.searchTerm"></ngb-highlight>
        </td>
        <td class="collapsible">
          <ngb-highlight [result]="c.chef_region" [term]="pagingService.searchTerm">
          </ngb-highlight>
        </td>
        <td class="collapsible">
          <ngb-highlight [result]="c.commercial" [term]="pagingService.searchTerm">
          </ngb-highlight>
        </td>
        <td class="collapsible">
          <ngb-highlight [result]="c.telephone" [term]="pagingService.searchTerm">
          </ngb-highlight>
        </td>
        <td class="">
          <ngb-highlight [result]="c.nim" [term]="pagingService.searchTerm">
          </ngb-highlight>
        </td>
        <td class="collapsible">
          <ngb-highlight [result]="c.client" [term]="pagingService.searchTerm">
          </ngb-highlight>
        </td>
        <td class="collapsible">
          <ngb-highlight [result]="c.smsok" [term]="pagingService.searchTerm">
          </ngb-highlight>
        </td>
        <td class="collapsible">{{c.totalphotos > 0 ?  c.totalphotos : ''}}</td>
        <td class="collapsible">
          <ngb-highlight [result]="c.commentaires" [term]="pagingService.searchTerm">
          </ngb-highlight>
        </td>
        <td>
          <div class="inline">
            <button type="button" title="Voir les photos" (click)="showPhoto(c)"
              class="mr-2 btn btn-sm btn-success w30"><i class="fa fa-camera"></i></button>
            <button type="button" title="Voir les stats" (click)="showCusto(c.nim)"
              class="mr-2 btn btn-primary btn-sm w30"><i class="fa fa-user"></i></button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="inline">
    <div class="">
      Total: {{(total$ | async)}}
    </div>
    <div class="ml-auto">
      <ngb-pagination class="d-flex justify-content-end" [collectionSize]="total$ | async" [(page)]="pagingService.page"
        [boundaryLinks]="true" [rotate]="true" [directionLinks]="true" [pageSize]="pagingService.pageSize" [maxSize]="3"
        name="page">
      </ngb-pagination>
    </div>
  </div>
</div>

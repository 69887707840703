import { Component, OnInit, Input } from '@angular/core';
import { IUser } from 'src/app/interfaces/user';
import { IAnnonce } from 'src/app/interfaces/annonce';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-annonce-stat',
  templateUrl: './annonce-stat.component.html',
  styleUrls: ['./annonce-stat.component.scss'],
  standalone: true,
  imports: [FormsModule]
})
export class AnnonceStatComponent implements OnInit {

  @Input() client: IUser = null;
  @Input() annonce: IAnnonce = null;

  constructor() { }

  ngOnInit() {
  }

}

import { ViewChildren, QueryList, Component, OnInit, OnDestroy, Injectable } from '@angular/core';
import { Observable, ReplaySubject, of } from 'rxjs';
import { SortEvent, NgbdSortableHeader } from 'src/app/directives/sortable.directive';


import { PagingService } from '../../providers/paging.service';
import { UserService } from 'src/app/providers/user.service';
import { Router, RouterModule } from '@angular/router';

import * as R from 'ramda';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { ICredit } from 'src/app/interfaces/credit';
import { IUser, User } from 'src/app/interfaces/user';
import { AuthenticationService } from 'src/app/providers/authentication.service';

import { NgbDateAdapter, NgbDateStruct, NgbDateNativeAdapter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { Chart } from 'chart.js';
import { ExportDataService } from 'src/app/providers/export-data.service';

import moment from 'moment';
import { TranslationWidth } from '@angular/common';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConsoPostpaidComponent } from '../conso/conso-postpaid.component';
import { ConsoRecapComponent } from '../conso/conso-recap.component';
import { ConsoForfaitComponent } from '../conso/conso-forfait.component';
import { ConsoPerAdminComponent } from '../conso/conso-per-admin.component';
import { IConsoPerDay } from 'src/app/interfaces/consoPerDay';
import { NgbHighlight, NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/modals/modal.component';
import { AsyncPipe } from '@angular/common';

const I18N_VALUES = {
  fr: {
    weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
    months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
  }
  // other languages you would support
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable()
export class I18n {
  language = 'fr';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  // tslint:disable-next-line:variable-name
  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    return this.getWeekdayShortName(weekday);
  }
}


@Component({
  selector: 'app-credit-admin',
  templateUrl: './credit-admin.component.html',
  styleUrls: ['./credit-admin.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, NgbModule, NgxSpinnerModule, ToastrModule, ConsoPerAdminComponent, ConsoPostpaidComponent, ConsoForfaitComponent, ConsoRecapComponent, RouterModule, NgbdSortableHeader, NgbHighlight, NgbPagination, ModalComponent, AsyncPipe],
  providers: [I18n, { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }]
})
export class CreditAdminComponent implements OnInit, OnDestroy {

  credits$: Observable<ICredit[] | IConsoPerDay[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;

  selectedCredit: ICredit = null;
  client: IUser = null;
  userSubscribe = null;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  page = 1;
  pageSize = 10;
  collectionSize = 1;
  searchFields = ['creditid', 'campaignid', 'history','company', 'label', 'dateoperation', 'premium', 'customerid'];
  isAdmin = this.authService.isAdmin;

  showDeleteConfirm = false;
  initialized = false;
  dateChanged = false;

  period = {
    startdate: new Date(moment().format('YYYY-MM-01')),
    stopdate: new Date(moment().format('YYYY-MM-') + moment().endOf('month').format('DD'))
  };
  origPeriod = R.clone(this.period);

  selectedPeriod = {
    startdate: moment(this.period.startdate).format('YYYY-MM-DD'),
    stopdate: moment(this.period.stopdate).format('YYYY-MM-DD'),
    admin: this.authService.adminId,
  };

  consoPeriod: any;
  consoSubscribe = null;
  chart = null;

  showAddCredit = false;
  creditType = 1;   // premium=1, lowcost=0
  credits = 0;

  activeTab = 0;
  isSpinnerVisible = false;

  // // provide your own if necessary
  // providers: [{provide: NgbDatepickerI18n, useClass: YourOwnDatepickerI18n}]

  constructor(
    public pagingService: PagingService<ICredit>,
    private authService: AuthenticationService,
    private userService: UserService,
    private toastr: ToastrService,
    private exportDataService: ExportDataService,
    private router: Router,
  ) {
    // this.client = new User();
    // this.client.id = 0;
    this.credits$ = pagingService.rowsObs;
    this.total$ = pagingService.totalObs;
    this.loading$ = pagingService.loadingObs;
    this.pagingService.searchTerm = '';
    // set fields for filtering
    this.pagingService.fields = this.searchFields;
    this.initialized = true;

    setTimeout(() => {
      this.selectTab(0);
    }, 0);
  }

  ngOnInit(): void {

    console.log('credits onInit', this.client);
    // if (!this.client) {
    //   this.router.navigate(['/']);
    // }

  }

  ngOnDestroy() {
    if (this.userSubscribe) {
      this.userSubscribe.unsubscribe();
    }
    if (this.consoSubscribe) {
      this.consoSubscribe.unsubscribe();
    }
    if (this.chart) {
      this.chart.destroy();
    }

  }

  pageChanged(event) {
    console.log('onPageChange', event);
  }

  selectTab(tab) {
    if (this.activeTab !== tab) {
      this.activeTab = tab;
      if (tab === 0) {
        setTimeout(() => {
          this.reloadTab0();
        }, 0);
        return;
      }
    }

    // same tab => force tab change to reload component0
    this.activeTab = -1;
    setTimeout(() => {
      this.activeTab = tab;
      if (tab === 0) {
        this.reloadTab0();
      }
      if (tab === 5) {
        this.reloadTab5();
        return;
      }
    }, 0);

  }

  reloadTab0() {
    this.pagingService.loadRows(of([]));
    this.onSort({ column: 'period', direction: 'desc' });
    this.pagingService.loadRows(this.userService.GetCreditsAdminHistoryPerDay(this.selectedPeriod));
    this.consoSubscribe = this.userService.GetCreditsHistoryAll().subscribe((conso: any) => {
      if (conso) {
        // console.log('consoPeriod=', conso);
        this.consoPeriod = conso;
        this.loadChart();
      }
    });
    this.pagingService.page = 1;
  }

  reloadTab5() {
    this.pagingService.loadRows(of([]));
    this.onSort({ column: 'creditid', direction: 'desc' });
    this.pagingService.loadRows(this.userService.GetCreditsAdminHistory(this.selectedPeriod));
    this.consoSubscribe = this.userService.GetCreditsHistoryAll().subscribe((conso: any) => {
      if (conso) {
        this.consoPeriod = conso;
        this.loadChart();
      }
    });
    this.pagingService.page = 1;
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.pagingService.sortColumn = column;
    this.pagingService.sortDirection = direction;
  }

  selectCredit(credit) {
    this.selectedCredit = R.clone(credit);
  }

  checkDate() {
    this.dateChanged = this.origPeriod.startdate !== this.period.startdate ||
      this.origPeriod.stopdate !== this.period.stopdate;
  }

  selectPeriod() {
    this.isSpinnerVisible = true;
    this.selectedPeriod = {
      ...this.selectedPeriod,
      startdate: moment(this.period.startdate).format('YYYY-MM-DD'),
      stopdate: moment(this.period.stopdate).format('YYYY-MM-DD'),
    };
    this.origPeriod = R.clone(this.period);
    this.dateChanged = false;
    this.pagingService.loadRows(this.userService.GetCreditsAdminHistoryPerDay(this.selectedPeriod));
  }


  selectPeriod5() {

    this.isSpinnerVisible = true;

    this.selectedPeriod = {
      ...this.selectedPeriod,
      startdate: moment(this.period.startdate).format('YYYY-MM-DD'),
      stopdate: moment(this.period.stopdate).format('YYYY-MM-DD'),
    };
    this.origPeriod = R.clone(this.period);
    this.dateChanged = false;

    this.pagingService.loadRows(this.userService.GetCreditsAdminHistory(this.selectedPeriod));
  }

  loadChart() {

    this.userService.GetConsoAdmin().subscribe((conso: any) => {
      if (conso) {
        this.isSpinnerVisible = false;

        setTimeout(() => {
          const histo = {
            labels: conso?.labels,
            datasets: [{
              label: 'Premium',
              backgroundColor: '#3fbfad55',
              borderColor: '#3fbfad',
              hoverBackgroundColor: '#3fbfad88',
              borderWidth: 1,
              data: conso.data,
            }]
          };


          if (this.chart) {
            this.chart.destroy();
          }
          this.chart = new Chart('canvas', {
            type: 'bar',
            data: histo,
            options: {
              responsive: true,
              legend: {
                display: false,
              },
              title: {
                display: false,
              }
            }
          });
          this.isSpinnerVisible = false;
        }, 100);
      }
    });

  }

  Export(kind) {
    console.log('export', kind);
    const records = [];
    const fields = ['creditid', 'campaignid', 'company', 'label', 'dateoperation', 'premium', 'lowcost'];

    for (const u of this.pagingService.filteredRows) {
      const user = R.pick(fields, u);
      records.push(user);
    }
    const header = {};
    fields.forEach(f => header[f] = f.replace(/[^a-z0-9]/gmi, '').toUpperCase());
    records.unshift(header);
    this.exportDataService.exportAs(kind, records, 'credits_' + moment().format('YYYY-MM-DD'));
  }

  selectAndShowCampaigns(credit) {
    this.userService.GetById(credit.customerid).subscribe((res: any) => {
      this.router.navigate(['/campagne/status/encours']);
    });
  }

  selectAndShowCredits(credit) {
    this.userService.GetById(credit.customerid).subscribe((res: any) => {
      this.router.navigate(['/client/credit']);
    });
  }

}


@if (authService.isAdmin) {
<div class="client-view">
  <!-- admin dashboard -->
  <div class="container scrollable">

    <p style="height: 5%">
    </p>

    <div class="row">
      <div class="col-12 col-md-4" (click)="openLinkNoCheck('/client/selection')">
        <div class="bg1 card-box menu-panel tilebox-one">
          <i class="left-icon zmdi zmdi-accounts-outline"></i>
          <h6 class="left-icon text-uppercase menu-title">Sélection client</h6>
        </div>
      </div>

      <div class="col-12 col-md-4" (click)="openLink('/client/edition')">
        <div class="bg2 card-box menu-panel tilebox-one">
          <i class="left-icon zmdi zmdi-odnoklassniki"></i>
          <h6 class="left-icon text-uppercase menu-title">Infos client</h6>
        </div>
      </div>

      <div class="col-12 col-md-4" (click)="openLink('/client/credit')">
        <div class="bg3 card-box menu-panel tilebox-one">
          <i class="left-icon zmdi zmdi-info"></i>
          <h6 class="left-icon text-uppercase menu-title">Crédits client</h6>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-12 col-md-4" (click)="openMenu(menu.list)">
        <div class="bg4 card-box menu-panel tilebox-one">
          <i class="left-icon zmdi zmdi-account-add"></i>
          <h6 class="left-icon text-uppercase menu-title">Liste de contacts</h6>
        </div>
      </div>
      <div class="col-12 col-md-4" (click)="openMenu(menu.campCreate)">
        <div class="bg5 card-box menu-panel tilebox-one">
          <i class="left-icon zmdi zmdi-comment-text"></i>
          <h6 class="left-icon text-uppercase menu-title">Créer une campagne</h6>
        </div>
      </div>

      <div class="col-12 col-md-4" (click)="openMenu(menu.campManage)">
        <div class="bg6 card-box menu-panel tilebox-one">
          <i class="left-icon zmdi zmdi-traffic"></i>
          <h6 class="left-icon text-uppercase menu-title">Etat des campagnes</h6>
        </div>
      </div>
    </div>

    <div class="justify-content-center text-center animate-if row" *ngIf="submenu===menu.list">
      <div class="col-4 minw" (click)="openLink('/contact/list')">
        <div class="card-box menu-subpanel1 tilebox-one">
          <h6 class="left-icon text-uppercase menu-subtitle">Gérer les listes</h6>
        </div>
      </div>
      <div class="col-4 minw" (click)="openLink('/contact/charger')">
        <div class="card-box menu-subpanel1 tilebox-one">
          <h6 class="left-icon text-uppercase menu-subtitle">Charger une liste</h6>
        </div>
      </div>
      <div class="col-4 minw" (click)="openLink('/blacklist')">
        <div class="card-box menu-subpanel1 tilebox-one">
          <h6 class="left-icon text-uppercase menu-subtitle">Blacklist</h6>
        </div>
      </div>
    </div>
    <div class="justify-content-center text-center animate-if row" *ngIf="submenu===menu.campCreate">
      <div class="col-4 minw" (click)="openLink('/campagne/creation/premium')">
        <div class="card-box menu-subpanel2 tilebox-one">
          <h6 class="left-icon text-uppercase menu-subtitle">Mailing</h6>
        </div>
      </div>
      <div class="col-4 minw" *ngIf="sitename!=='isisms.fr' && client.option_lowcost=='1'"
        (click)="openLink('/campagne/creation/lowcost')">
        <div class="card-box menu-subpanel2 tilebox-one">
          <h6 class="left-icon text-uppercase menu-subtitle">Lowcost</h6>
        </div>
      </div>
      <div class="col-4 minw" (click)="openLink('/campagne/creation/unitaire')">
        <div class="card-box menu-subpanel2 tilebox-one">
          <h6 class="left-icon text-uppercase menu-subtitle">Unitaire</h6>
        </div>
      </div>
    </div>

    <div class="justify-content-center text-center animate-if row" *ngIf="submenu===menu.campManage">
      <div class="col-4 minw" (click)="openLink('/campagne/status/preparation')">
        <div class="card-box menu-subpanel1 tilebox-one">
          <h6 class="left-icon text-uppercase menu-subtitle">En préparation</h6>
        </div>
      </div>
      <div class="col-4 minw" (click)="openLink('/campagne/status/encours')">
        <div class="card-box menu-subpanel1 tilebox-one">
          <h6 class="left-icon text-uppercase menu-subtitle">En cours</h6>
        </div>
      </div>
      <div class="col-4 minw" (click)="openLink('/campagne/status/archivee')">
        <div class="card-box menu-subpanel1 tilebox-one">
          <h6 class="left-icon text-uppercase menu-subtitle">Archivées</h6>
        </div>
      </div>
    </div>



    <div class="row">
      <div class="col-12 col-md-4" (click)="openLinkNoCheck('/credit/admin')">
        <div class="bg5 card-box menu-panel tilebox-one">
          <i class="left-icon zmdi zmdi-trending-up"></i>
          <h6 class="left-icon text-uppercase menu-title">Voir les consommations</h6>
        </div>
      </div>
      <div class="col-12 col-md-4" (click)="logout()">
        <div class="bg6 card-box menu-panel tilebox-one">
          <i class="left-icon zmdi zmdi-power"></i>
          <h6 class="left-icon text-uppercase menu-title">Déconnexion</h6>
        </div>
      </div>
      <div *ngIf="authService.adminRole==2" class="col-12 col-md-4" (click)="openLinkNoCheck('/admin/manager')">
        <div class="bg5 card-box menu-panel tilebox-one">
          <i class="left-icon zmdi zmdi-lock"></i>
          <h6 class="left-icon text-uppercase menu-title">Comptes Admin</h6>
        </div>
      </div>

    </div>

    <!-- <div class="row" *ngIf="clientMLP || clientADE || client?.option_mosms"> -->
      <!-- <div class="col-12 col-md-4" (click)="openLinkNoCheck('/mlp')" *ngIf="clientMLP">
        <div class="bg6 card-box menu-panel tilebox-one">
          <i class="left-icon zmdi zmdi-camera"></i>
          <h6 class="left-icon text-uppercase menu-title">Campagne photos MLP</h6>
        </div>
      </div>
      <div class="col-12 col-md-4" (click)="openLinkNoCheck('/ade')" *ngIf="clientADE">
        <div class="bg6 card-box menu-panel tilebox-one">
          <i class="left-icon zmdi zmdi-account-calendar"></i>
          <h6 class="left-icon text-uppercase menu-title">Formations ADE</h6>
        </div>
      </div> -->
      <!-- <div class="col-12 col-md-4" (click)="openLinkNoCheck('/annonce')" *ngIf="client && client.option_mosms">
        <div class="bg5 card-box menu-panel tilebox-one">
          <i class="left-icon zmdi zmdi-account-calendar"></i>
          <h6 class="left-icon text-uppercase menu-title">Gestion des annonces</h6>
        </div>
      </div> -->
    <!-- </div> -->

  </div>
</div>


} @else {


<div class="client-view">
  <!-- client dashboard -->
  <div class="container scrollable">

    <p style="height: 5%">
    </p>

    <div class="row">
      <div class="col-12 col-md-4" (click)="openMenu(menu.list)">
        <div class="bg1 card-box menu-panel tilebox-one">
          <i class="left-icon zmdi zmdi-accounts-add"></i>
          <h6 class="left-icon text-uppercase menu-title">Liste de contacts</h6>
        </div>
      </div>
      <div class="col-12 col-md-4" (click)="openMenu(menu.campCreate)">
        <div class="bg2 card-box menu-panel tilebox-one">
            <i class="left-icon zmdi zmdi-comment-text"></i>
          <h6 class="left-icon text-uppercase menu-title">Créer une campagne</h6>
        </div>
      </div>

      <div class="col-12 col-md-4" (click)="openMenu(menu.campManage)">
        <div class="bg3 card-box menu-panel tilebox-one">
          <i class="left-icon zmdi zmdi-traffic"></i>
          <h6 class="left-icon text-uppercase menu-title">Mes campagnes</h6>
        </div>
      </div>
    </div>

    <div class="justify-content-center text-center animate-if row" *ngIf="submenu===menu.list">
      <div class="col-4 minw" (click)="openLinkNoCheck('/contact/list')">
        <div class="card-box menu-subpanel1 tilebox-one">
          <h6 class="left-icon text-uppercase menu-subtitle">Gérer les listes</h6>
        </div>
      </div>
      <div class="col-4 minw" (click)="openLinkNoCheck('/contact/charger')">
        <div class="card-box menu-subpanel1 tilebox-one">
          <h6 class="left-icon text-uppercase menu-subtitle">Charger une liste</h6>
        </div>
      </div>
      <div class="col-4 minw" (click)="openLinkNoCheck('/blacklist')">
        <div class="card-box menu-subpanel1 tilebox-one">
          <h6 class="left-icon text-uppercase menu-subtitle">Blacklist</h6>
        </div>
      </div>
    </div>
    <div class="justify-content-center text-center animate-if row" *ngIf="submenu===menu.campCreate">
      <div class="col-4 minw" (click)="openLinkNoCheck('/campagne/creation/premium')">
        <div class="card-box menu-subpanel2 tilebox-one">
          <h6 class="left-icon text-uppercase menu-subtitle">Mailing</h6>
        </div>
      </div>
      <div class="col-4 minw" *ngIf="sitename!=='isisms.fr' && client.option_lowcost=='1'"
        (click)="openLinkNoCheck('/campagne/creation/lowcost')">
        <div class="card-box menu-subpanel2 tilebox-one">
          <h6 class="left-icon text-uppercase menu-subtitle">Lowcost</h6>
        </div>
      </div>
      <div class="col-4 minw" (click)="openLinkNoCheck('/campagne/creation/unitaire')">
        <div class="card-box menu-subpanel2 tilebox-one">
          <h6 class="left-icon text-uppercase menu-subtitle">Unitaire</h6>
        </div>
      </div>
    </div>

    <div class="justify-content-center text-center animate-if row" *ngIf="submenu===menu.campManage">
      <div class="col-4 minw" (click)="openLinkNoCheck('/campagne/status/preparation')">
        <div class="card-box menu-subpanel1 tilebox-one">
          <h6 class="left-icon text-uppercase menu-subtitle">En préparation</h6>
        </div>
      </div>
      <div class="col-4 minw" (click)="openLinkNoCheck('/campagne/status/encours')">
        <div class="card-box menu-subpanel1 tilebox-one">
          <h6 class="left-icon text-uppercase menu-subtitle">En cours</h6>
        </div>
      </div>
      <div class="col-4 minw" (click)="openLinkNoCheck('/campagne/status/archivee')">
        <div class="card-box menu-subpanel1 tilebox-one">
          <h6 class="left-icon text-uppercase menu-subtitle">Archivées</h6>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-4" (click)="openLinkNoCheck('/client/edition')">
        <div class="bg4 card-box menu-panel tilebox-one">
          <i class="left-icon zmdi zmdi-odnoklassniki"></i>
          <h6 class="left-icon text-uppercase menu-title">Mes infos<br>Mes modèles<br>Mes factures</h6>

        </div>
      </div>

      <div class="col-12 col-md-4" (click)="openLinkNoCheck('/client/credit')">
        <div class="bg5 card-box menu-panel tilebox-one">
          <i class="left-icon zmdi zmdi-info"></i>
          <h6 class="left-icon text-uppercase menu-title">Mes crédits</h6>
        </div>
      </div>

      <div class="col-12 col-md-4" (click)="openLinkNoCheck('/nous_contacter')">
        <div class="bg6 card-box menu-panel tilebox-one">
          <i class="left-icon zmdi zmdi-email"></i>
          <h6 class="left-icon text-uppercase menu-title">Nous contacter</h6>

        </div>
      </div>
    </div>


    <div class="row">

      <div class="col-12 col-md-4" (click)="logout()">
        <div class="bg9 card-box menu-panel tilebox-one">
          <i class="left-icon zmdi zmdi-power"></i>
          <h6 class="left-icon text-uppercase menu-title">Déconnexion</h6>
        </div>
      </div>
    </div>

    <!-- <div class="row" *ngIf="clientMLP || clientADE || client?.option_mosms">
      <div class="col-12 col-md-4" (click)="openLinkNoCheck('/mlp')" *ngIf="clientMLP">
        <div class="bg6 card-box menu-panel tilebox-one">
          <i class="left-icon zmdi zmdi-camera"></i>
          <h6 class="left-icon text-uppercase menu-title">Campagne photos MLP</h6>
        </div>
      </div>
      <div class="col-12 col-md-4" (click)="openLinkNoCheck('/ade')" *ngIf="clientADE">
        <div class="bg6 card-box menu-panel tilebox-one">
          <i class="left-icon zmdi zmdi-account-calendar"></i>
          <h6 class="left-icon text-uppercase menu-title">Formations ADE</h6>
        </div>
      </div>
      <div class="col-12 col-md-4" (click)="openLinkNoCheck('/annonce')" *ngIf="client && client.option_mosms">
        <div class="bg5 card-box menu-panel tilebox-one">
          <i class="left-icon zmdi zmdi-account-calendar"></i>
          <h6 class="left-icon text-uppercase menu-title">Gestion des annonces</h6>
        </div>
      </div>
    </div> -->
  </div>
</div>
}

<app-modal [(isActive)]="showLogoutConfirm" [overlayDismiss]="true" (overlayClick)="showLogoutConfirm=false">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Déconnexion ? </h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showLogoutConfirm=false">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-dismiss="modal"
      (click)="showLogoutConfirm=false">Annuler</button>
      <button type="button" class="btn btn-primary" (click)='logoutConfirm()'>Valider</button>
    </div>
  </div>
</app-modal>



import { ViewChildren, QueryList, Component, OnInit, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, of } from 'rxjs';
import { SortEvent, NgbdSortableHeader } from 'src/app/directives/sortable.directive';
import { environment } from '../../../environments/environment';

import { PagingService } from '../../providers/paging.service';
import { UserService } from 'src/app/providers/user.service';
import { Router } from '@angular/router';

import * as R from 'ramda';
import { ExportDataService } from 'src/app/providers/export-data.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NgbHighlight, NgbModal, NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { IUser } from 'src/app/interfaces/user';
import { IBlacklist, Blacklist } from 'src/app/interfaces/blacklist';
import { BlacklistService } from 'src/app/providers/blacklist.service';
import { AsyncPipe } from '@angular/common';
import { SpinnerComponent } from '../spinner/spinner.component';
import { ClientSelectComponent } from '../client-select/client-select.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ModalComponent } from 'src/app/modals/modal.component';

@Component({
  selector: 'app-blacklist',
  templateUrl: './blacklist.component.html',
  styleUrls: ['./blacklist.component.scss'],
  standalone: true,
  imports: [SpinnerComponent, ClientSelectComponent, FormsModule, NgbdSortableHeader, CommonModule, NgbHighlight, NgbPagination, ModalComponent, AsyncPipe]
})
export class BlacklistComponent implements OnInit, OnDestroy {

  blacklist$: Observable<IBlacklist[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;

  client: IUser = null;
  mobile = '';
  selectedBlacklist: IBlacklist = new Blacklist(0, 0, '');

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  page = 1;
  pageSize = 10;
  collectionSize = 1;
  searchFields = ['mobile', 'date_added'];

  showDeleteConfirm = false;
  showAddMobile = false;
  initialized = false;

  userSubscribe = null;
  isSpinnerVisible = false;

  constructor(
    public pagingService: PagingService<IBlacklist>,
    private userService: UserService,
    private exportDataService: ExportDataService,
    private toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal,
    private blacklistService: BlacklistService,
  ) {
    this.blacklist$ = pagingService.rowsObs;
    this.total$ = pagingService.totalObs;
    this.loading$ = pagingService.loadingObs;
    this.pagingService.searchTerm = '';
    // set fields for filtering
    this.pagingService.fields = this.searchFields;

  }

  ngOnDestroy() {
    if (this.userSubscribe) {
      this.userSubscribe.unsubscribe();
    }
  }

  ngOnInit(): void {

    console.log('modeles onInit', this.client);
    this.pagingService.loadRows(of([]));

    this.userSubscribe = this.userService.selectedUser$.subscribe(user => {
      if (user) {
        this.client = user;
        if (this.client) {
          this.pagingService.loadRows(this.blacklistService.GetAll(this.client.id));
          this.loading$.subscribe(loading => {
            if (loading) {
              this.isSpinnerVisible = true;
            } else {
              this.isSpinnerVisible = false;
              this.initialized = true;
            }
          });
          this.pagingService.page = 1;
        }
      }
    });
  }

  pageChanged(event) {
    console.log('onPageChange', event);
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.pagingService.sortColumn = column;
    this.pagingService.sortDirection = direction;
  }

  selectMobile(mobile) {
    this.selectedBlacklist = R.clone(mobile);
  }

  addMobile() {
    if (!this.client || this.client.id === 0) { return; }
    this.isSpinnerVisible = true;
    this.blacklistService.Add(this.client.id, { mobile: this.mobile })
      .subscribe((response: any) => {
          if (response.success) {
            this.toastr.success('Le numéro \'' + this.mobile + '\' a été ajouté à la blacklist');
            this.pagingService.loadRows(this.blacklistService.GetAll(this.client.id));
          } else {
            this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
          }
          this.isSpinnerVisible = false;
    });
    this.showAddMobile = false;
  }

  Export(kind) {
    console.log('export', kind);
    const records = [];
    const fields = ['mobile', 'date_added'];
    for (const u of this.pagingService.filteredRows) {
      const model = R.pick(fields, u);
      records.push(model);
    }
    const header = {};
    fields.forEach(f => header[f] = f.replace(/[^a-z0-9]/gmi, '').toUpperCase());
    records.unshift(header);
    this.exportDataService.exportAs(kind, records, 'user');
  }

  Delete(blacklist) {
    this.selectedBlacklist = blacklist;
    this.showDeleteConfirm = true;
    console.log('show delete confirm');
  }

  DeleteConfirm() {
    this.showDeleteConfirm = false;
    this.isSpinnerVisible = true;

    this.blacklistService.Delete(this.client.id, this.selectedBlacklist.id).subscribe((response: any) => {
      if (response.success) {
        this.toastr.success('Le numéro est de nouveau actif', '');
        this.pagingService.loadRows(this.blacklistService.GetAll(this.client.id));
      } else {
        this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
      }
      this.isSpinnerVisible = false;
    });
  }

}

import { Component, OnInit, Input, ViewChildren, QueryList, Injectable, Output, EventEmitter } from '@angular/core';
import { UserService } from 'src/app/providers/user.service';
import { IUser } from 'src/app/interfaces/user';
import { MlpService } from 'src/app/providers/mlp.service';
import { AuthenticationService } from 'src/app/providers/authentication.service';

import moment from 'moment';
import * as R from 'ramda';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbdSortableHeader, SortEvent } from 'src/app/directives/sortable.directive';
import { PagingService } from 'src/app/providers/paging.service';
import { IModele } from 'src/app/interfaces/modele';
import { ExportDataService } from 'src/app/providers/export-data.service';
import { Observable, of } from 'rxjs';
import { NgbDateAdapter, NgbDateStruct, NgbDateNativeAdapter, NgbDatepickerI18n, NgbInputDatepicker, NgbHighlight, NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { IMlpStat } from 'src/app/interfaces/mlp';
import { AsyncPipe, CommonModule, TranslationWidth } from '@angular/common';
import { FormsModule } from '@angular/forms';
const I18N_VALUES = {
  fr: {
    weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
    months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
  }
  // other languages you would support
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable()
export class I18n {
  language = 'fr';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  // tslint:disable-next-line:variable-name
  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    return this.getWeekdayShortName(weekday);
  }
}


@Component({
  selector: 'app-mlp-list',
  templateUrl: './mlp-list.component.html',
  styleUrls: ['./mlp-list.component.scss'],
  providers: [I18n, { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
  standalone: true,
  imports: [FormsModule, NgbInputDatepicker, CommonModule, NgbdSortableHeader, NgbHighlight, NgbPagination, AsyncPipe]
})
export class MlpListComponent implements OnInit {

  @Input() client: IUser = null;
  @Input() commerciaux = [];
  @Input() chefsRegion = [];
  @Input() customers = [];
  @Output() selectedCampaigns: EventEmitter<any> = new EventEmitter<any>();

  dateChanged = false;

  filterParams = {
    startdate: new Date(moment().format('YYYY-MM-01')),
    stopdate: new Date(moment().format('YYYY-MM-') + moment().endOf('month').format('DD')),
    admin: this.authService.adminId,
    commid: 0,
    chefid: 0,
  };
  origParams = R.clone(this.filterParams);


  allCDR = { id: 0, label: 'Tous'};
  allCOMM = { id: 0, label: 'Tous'};

  stats = [];
  selectedStats = 0;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  page = 1;
  pageSize = 10;
  collectionSize = 1;
  searchFields = ['id', 'name', 'datecreation', 'nbclients', 'nbphotos', 'nbsms', 'totalphotos'];

  campaigns$: Observable<IMlpStat[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  initialized = false;

  selectedCampaign = null;
  selected = [];

  constructor(
    public pagingService: PagingService<IMlpStat>,
    private exportDataService: ExportDataService,
    private userService: UserService,
    private mlpService: MlpService,
    private authService: AuthenticationService,
    private spinner: NgxSpinnerService,
  ) {
    this.campaigns$ = pagingService.rowsObs;
    this.total$ = pagingService.totalObs;
    this.loading$ = pagingService.loadingObs;
    this.pagingService.searchTerm = '';
    // set fields for filtering
    this.pagingService.fields = this.searchFields;
  }

  ngOnInit() {

    // load MLP campaign
    this.pagingService.loadRows(of([]));
    this.pagingService.loadRows(this.mlpService.GetMlpAllStat(0, this.filterParams));
    this.loading$.subscribe(loading => {
      if (loading) {
        this.spinner.show();
      } else {
        this.spinner.hide();
        this.initialized = true;
      }
    });
    this.pagingService.page = 1;
    setTimeout(() => {
        console.log('count selected');
        this.setSelected();
    }, 1000);
  }

  pageChanged(event) {
    console.log('onPageChange', event);
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.pagingService.sortColumn = column;
    this.pagingService.sortDirection = direction;
  }

  checkDate() {
    this.dateChanged =
    this.origParams.startdate !== this.filterParams.startdate ||
    this.origParams.stopdate !== this.filterParams.stopdate ||
    this.origParams.commid !== this.filterParams.commid ||
    this.origParams.chefid !== this.filterParams.chefid;
  }

  applyFilters() {
    this.pagingService.loadRows(this.mlpService.GetMlpAllStat(0, this.filterParams));
    this.origParams = R.clone(this.filterParams);
    this.dateChanged = false;
    setTimeout(() => {
      console.log('count selected');
      this.setSelected();
    }, 1000);
  }


  getPercentage(c) {

   return c.nbphotos > 0 && c.nbsms > 0 ?
          Math.floor(c.totalphotos * 100 / c.nbphotos / c.nbsms) + '%' :
          '';
  }

  ToggleSelectCampaign(c) {
    c.selected = 1 - c.selected;
    this.setSelected();
  }

  setSelected() {
    this.selected = [];
    this.pagingService.allRows.forEach((r: any) => {
      if (r.selected) {
        this.selected.push(r);
      }
    });
  }

  selectAndShowCampaign(c) {
    // signal we select a campaign
    this.selectedCampaigns.emit({ campaigns: [c], filterParams: this.filterParams });
  }

  showSelected() {
    // signal we select a set of campaign
    this.selectedCampaigns.emit({ campaigns: this.selected, filterParams: this.filterParams });
  }

  Export(kind) {
    console.log('export', kind);
    const records = [];
    const fields = ['id', 'name', 'datecreation', 'nbclients', 'nbphotos', 'nbsms', 'totalphotos'];


    for (const u of this.pagingService.filteredRows) {
      const user = R.pick(fields, u);
      records.push(user);
    }
    const header = {};
    fields.forEach(f => header[f] = f.replace(/[^a-z0-9]/gmi, '').toUpperCase());
    records.unshift(header);
    this.exportDataService.exportAs(kind, records, 'credits_' + moment().format('YYYY-MM-DD') );
  }

}

import { ViewChildren, QueryList, Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { Observable, ReplaySubject, of } from 'rxjs';
import { SortEvent, NgbdSortableHeader } from 'src/app/directives/sortable.directive';
import { environment } from '../../../environments/environment';

import { PagingService } from '../../providers/paging.service';
import { UserService } from 'src/app/providers/user.service';
import { Router } from '@angular/router';

import * as R from 'ramda';
import { ExportDataService } from 'src/app/providers/export-data.service';
import { ToastrService } from 'ngx-toastr';
import { NgbHighlight, NgbModal, NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { IModele, Modele } from 'src/app/interfaces/modele';
import { IUser } from 'src/app/interfaces/user';
import { AsyncPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ModalComponent } from 'src/app/modals/modal.component';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  selector: 'app-modeles',
  templateUrl: './modeles.component.html',
  styleUrls: ['./modeles.component.scss'],
  standalone: true,
  imports: [FormsModule, NgbdSortableHeader, CommonModule, NgbHighlight, NgbPagination, ModalComponent, AsyncPipe, SpinnerComponent]
})
export class ModelesComponent implements OnInit {

  modeles$: Observable<IModele[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;

  users: IModele[] = [];
  selectedModele: IModele = new Modele(0, false, '', '', 0, 0);

  @Input() client: IUser;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  page = 1;
  pageSize = 10;
  collectionSize = 1;
  searchFields = ['id', 'label', 'message'];

  showDeleteConfirm = false;
  showAddModele = false;
  showEditModele = false;
  initialized = false;
  computedDefault = { long: 0, sms: 0 };
  computed = this.computedDefault;
  isSpinnerVisible = false;

  constructor(
    public pagingService: PagingService<IModele>,
    private userService: UserService,
    private exportDataService: ExportDataService,
    private toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal,
  ) {
    this.modeles$ = pagingService.rowsObs;
    this.total$ = pagingService.totalObs;
    this.loading$ = pagingService.loadingObs;
    this.pagingService.searchTerm = '';
    // set fields for filtering
    this.pagingService.fields = this.searchFields;


  }

  ngOnInit(): void {

    console.log('modeles onInit', this.client);
    this.pagingService.loadRows(of([]));
    if (this.client) {
      this.pagingService.loadRows(this.userService.GetModeles(this.client.id, 0));
      this.loading$.subscribe(loading => {
        if (loading) {
          this.isSpinnerVisible = true;
        } else {
          this.isSpinnerVisible = false;
          this.initialized = true;
        }
      });
      this.pagingService.page = 1;
    }
  }

  pageChanged(event) {
    console.log('onPageChange', event);
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.pagingService.sortColumn = column;
    this.pagingService.sortDirection = direction;
  }

  selectModele(modele) {
    this.selectedModele = R.clone(modele);
    this.computeMsgLen();
  }

  computeMsgLen() {
    if (typeof this.selectedModele === 'undefined' ) {
      this.computed = { long: 0, sms: 0 };
      return 0;
    }
    this.selectedModele.message = this.selectedModele.message.replace(/…/g, '...');
    this.selectedModele.message = this.selectedModele.message.replace(/`/g, '\'');
    this.selectedModele.message = this.selectedModele.message.replace(/’/g, '\'');
    this.selectedModele.message = this.selectedModele.message.replace(/«/g, '"');
    this.selectedModele.message = this.selectedModele.message.replace(/»/g, '"');
    const len =  this.selectedModele.message.replace(/([{}\[\]\\|\^~€])/g, '\\$1').replace(/\n/g, '-').length;
    let sms = 1;
    if (len > 160) { sms = Math.ceil(len / 153); }
    // tslint:disable-next-line:object-literal-shorthand
    this.computed = { long: len, sms: sms };
  }


  Edit(modele) {
    this.selectedModele = R.clone(modele);
    this.computeMsgLen();
    this.showEditModele = true;
  }

  Add() {
    if (!this.client || this.client.id === 0) { return; }
    this.selectedModele = new Modele(this.client.id, false, '', '', 0, 0);
    this.showEditModele = true;
  }

  UpdateModele() {
    this.isSpinnerVisible = true;

    if (this.selectedModele.id > 0) {
        this.userService.UpdateModele(this.client.id, this.selectedModele)
        .subscribe((response: any) => {
            if (response.success) {
                this.toastr.success('Le modèle \'' + this.selectedModele.label + '\' a été modifié');
                this.pagingService.loadRows(this.userService.GetModeles(this.client.id, 0));
            } else {
              this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
            }
            this.isSpinnerVisible = false;
        });
    } else {
      this.userService.AddModele(this.client.id, this.selectedModele)
        .subscribe((response: any) => {
            if (response.success) {
              this.toastr.success('Le modèle \'' + this.selectedModele.label + '\' a été ajouté');
              this.pagingService.loadRows(this.userService.GetModeles(this.client.id, 0));
            } else {
              this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
            }
            this.isSpinnerVisible = false;
        });
    }
    this.showEditModele = false;
  }

  // Export(kind) {
  //   console.log('export', kind);
  //   const records = [];
  //   const fields = ['id', 'label', 'message', 'longueur', 'sms'];
  //   for (const u of this.pagingService.filteredRows) {
  //     const model = R.pick(fields, u);
  //     records.push(model);
  //   }
  //   const header = {};
  //   fields.forEach(f => header[f] = f.replace(/[^a-z0-9]/gmi, '').toUpperCase());
  //   records.unshift(header);
  //   this.exportDataService.exportAs(kind, records, 'user');
  // }

  Delete(modele) {
    this.selectedModele = modele;
    this.showDeleteConfirm = true;
    console.log('show delete confirm');
  }

  DeleteConfirm() {
    this.showDeleteConfirm = false;
    console.log('call delete api');
    this.isSpinnerVisible = true;

    this.userService.DeleteModele(this.client.id, this.selectedModele.id).subscribe((response: any) => {
      if (response.success) {
        this.toastr.success('Le modèle a été supprimé', '');
        this.pagingService.loadRows(this.userService.GetModeles(this.client.id, 0));
      } else {
        this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
      }
      this.isSpinnerVisible = false;
    });
  }

}

import { Component, OnInit, OnDestroy, Injectable } from '@angular/core';

import { PagingService } from '../../providers/paging.service';
import { UserService } from 'src/app/providers/user.service';
import { Router, ActivatedRoute } from '@angular/router';

import * as R from 'ramda';
import moment from 'moment';
import { ExportDataService } from 'src/app/providers/export-data.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NgbInputDatepicker, NgbModal, NgbTimepicker } from '@ng-bootstrap/ng-bootstrap';
import { IModele } from 'src/app/interfaces/modele';
import { IUser } from 'src/app/interfaces/user';
import { CampaignService } from 'src/app/providers/campaign.service';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { ContactListService } from 'src/app/providers/contact-list.service';
import { CommonModule, TranslationWidth } from '@angular/common';

import { NgbDateAdapter, NgbDateStruct, NgbDateNativeAdapter, NgbDatepickerI18n, NgbTimeAdapter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { ClientSelectComponent } from '../client-select/client-select.component';
import { SpinnerComponent } from '../spinner/spinner.component';
import { FormsModule } from '@angular/forms';
import { AppPatternDirective } from 'src/app/directives/app-pattern.directive';
import { ModalComponent } from 'src/app/modals/modal.component';


/**
 * Example of a String Time adapter
 */
@Injectable()
export class NgbTimeStringAdapter extends NgbTimeAdapter<string> {

  fromModel(value: string): NgbTimeStruct {
    if (!value) {
      return null;
    }
    const time = moment(value);
    return {
      hour: time.hours(),
      minute: time.minutes(),
      second: time.seconds()
    };
  }

  toModel(time: NgbTimeStruct): string {
    if (!time) {
      return null;
    }
    const now = moment();
    now.hour(time.hour);
    now.minute(time.minute);
    now.second(0);
    return now.format('YYYY-MM-DD HH:mm:ss');
  }

  private pad(i: number): string {
    return i < 10 ? `0${i}` : `${i}`;
  }
}

export class NgbdTimepickerAdapter {
  time: '08:00:00';
}

const I18N_VALUES = {
  fr: {
    weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
    months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
  }
  // other languages you would support
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable()
export class I18n {
  language = 'fr';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  // tslint:disable-next-line:variable-name
  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    return this.getWeekdayShortName(weekday);
  }
}


@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss'],
  providers: [I18n, { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }, { provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }],
  standalone: true,
  imports: [SpinnerComponent, ClientSelectComponent, FormsModule, CommonModule, AppPatternDirective, NgbInputDatepicker, NgbTimepicker, ModalComponent]
})
export class CampaignComponent implements OnInit, OnDestroy {

  client: IUser;
  computedDefault = { long: 0, sms: 0 };
  computed = this.computedDefault;

  clientSubscribe = null;

  activeTab = 0;
  activeTab2 = 0;
  maxTab = 2;
  maxTab2 = 1;


  defaultCampaign = {
    id: 0,
    customerid: 0,
    name: '',
    message: '',
    fromname: '',
    description: '',
    datestart: '',
    datestop: '',
    starttime: '',
    stoptime: '',
    schedule: [],
    weekday: [
      { checked: '0', label: 'Dimanche' },
      { checked: '1', label: 'Lundi' },
      { checked: '1', label: 'Mardi' },
      { checked: '1', label: 'Mercredi' },
      { checked: '1', label: 'Jeudi' },
      { checked: '1', label: 'Vendredi' },
      { checked: '0', label: 'Samedi' }],
    timerange: [
      { checked: '0', label: '00' },
      { checked: '0', label: '01' },
      { checked: '0', label: '02' },
      { checked: '0', label: '03' },
      { checked: '0', label: '04' },
      { checked: '0', label: '05' },
      { checked: '0', label: '06' },
      { checked: '0', label: '07' },
      { checked: '0', label: '08' },
      { checked: '0', label: '09' },
      { checked: '0', label: '10' },
      { checked: '0', label: '11' },
      { checked: '0', label: '12' },
      { checked: '0', label: '13' },
      { checked: '0', label: '14' },
      { checked: '0', label: '15' },
      { checked: '0', label: '16' },
      { checked: '0', label: '17' },
      { checked: '0', label: '18' },
      { checked: '0', label: '19' },
      { checked: '0', label: '20' },
      { checked: '0', label: '21' },
      { checked: '0', label: '22' },
      { checked: '0', label: '23' }
    ],
    statut: '0',
    total: '0',
    progress: '0',
    sendretry: '0',
    priority: '1',
    endreceipt: '',
    cost: '0',
    datecreation: '',
    datemodification: '',
    fields: '[]',
    asap: 1,
    destination_list: '',
    seq_type: '0',
    seq_nb_per_period: '0',
    seq_delay: '0',
    seq_duration: '',
    seq_minutes: '0',
    total_contact: 0
  };
  stops = ['36105', '36105', '36105'];
  patternFromName = '^[a-zA-Z][a-zA-Z0-9\-_+*\/]{10}$';
  defaultTimerange = [
    { checked: '0', label: '00' },
    { checked: '0', label: '01' },
    { checked: '0', label: '02' },
    { checked: '0', label: '03' },
    { checked: '0', label: '04' },
    { checked: '0', label: '05' },
    { checked: '0', label: '06' },
    { checked: '0', label: '07' },
    { checked: '0', label: '08' },
    { checked: '0', label: '09' },
    { checked: '0', label: '10' },
    { checked: '0', label: '11' },
    { checked: '0', label: '12' },
    { checked: '0', label: '13' },
    { checked: '0', label: '14' },
    { checked: '0', label: '15' },
    { checked: '0', label: '16' },
    { checked: '0', label: '17' },
    { checked: '0', label: '18' },
    { checked: '0', label: '19' },
    { checked: '0', label: '20' },
    { checked: '0', label: '21' },
    { checked: '0', label: '22' },
    { checked: '0', label: '23' }];
  schedNewDay = '';
  campaign = R.clone(this.defaultCampaign);

  modeles = [];
  modeleDefault = { id: 0, message: '', label: 'Sélectionnez un modèle ....', islast: 0 };
  modele = this.modeleDefault;
  showAddModele = false;
  modeleLabel = '';

  lists = [];
  fields = [];
  totalContact: number;

  listDefault = { id: 0, visible: true, actif: 0, contacts: [] };
  list = R.clone(this.listDefault);
  filter = '';
  showListFilterModal = false;
  fieldToAdd = '';
  isSpinnerVisible = false;

  constructor(
    public pagingService: PagingService<IModele>,
    private userService: UserService,
    private contactListService: ContactListService,
    private campaignService: CampaignService,
    private exportDataService: ExportDataService,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private authService: AuthenticationService,
  ) {
    console.log('campaign ctor');

    this.activeTab = 0;

    const action = this.activatedRoute.snapshot.params.action;

    let priority = 1;
    if (action === 'creation') {
      switch (this.activatedRoute.snapshot.params.param) {
        case 'lowcost':
          priority = 0;
          break;
        case 'unitaire':
          priority = 2;
          break;
        case 'premium':
        default:
          priority = 1;
          break;
      }
    }

    this.clientSubscribe = this.userService.selectedUser$.subscribe(user => {
      if (user) {
        this.client = R.clone(user);
        this.contactListService.GetAll(this.client.id)
          .subscribe((lists: any[]) => {
            this.lists = lists;
            console.log('lists', this.lists);

            // once list are loaded , display campagin
            if (action === 'creation') {
              this.createCampaign(priority, '');
            } else {
              this.editCampaign(this.activatedRoute.snapshot.params.param);
            }

            if (this.contactListService.selectedCList) {
              console.log('selectCList', this.contactListService.selectedCList.id);
              const selectedList = this.lists.find(l => l.id === this.contactListService.selectedCList.id);
              if (selectedList && !selectedList.selected) {
                this.listSelect(selectedList);
              }
            }

          });
        this.loadModeles();
      }
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (!this.client) {
        this.toastr.warning('Sélectionner un client en premier', '');
        this.router.navigate(['/']);
      }
    }, 1000);
  }

  ngOnDestroy() {
    if (this.clientSubscribe) {
      this.clientSubscribe.unsubscribe();
    }
  }

  loadModeles() {
    this.userService.GetModeles(this.client.id, 1).subscribe((modeles: any[]) => {
      this.modeles = modeles;
    });
  }

  createCampaign(priority, name) {
    const now = new Date(Date.now());
    this.campaign = R.clone(this.defaultCampaign);

    if (name === '') {
      this.campaign.name = 'Campagne ' + moment(now).format('YYYY-MM-DD');
    } else {
      this.campaign.name = name;
    }
    this.campaign.startdate = new Date(Date.now());
    this.campaign.stopdate = new Date(Date.now());
    this.campaign.starttime = new Date(Date.now());
    this.campaign.starttime.setMinutes(0);
    this.campaign.starttime.setHours(8);
    this.campaign.stoptime = new Date(Date.now());
    this.campaign.stoptime.setMinutes(0);
    this.campaign.stoptime.setHours(18);
    this.campaign.endreceipt = this.client.username;
    this.campaign.fromname = this.client.fromname;
    this.campaign.priority = priority;

    this.fields = this.campaignService.intersectFields(this.campaign, this.lists);

    this.computeMaxTab();
    this.activeTab = 0;
    this.activeTab2 = 0;

    this.SchedCheck();
  }

  editCampaign(paramId) {
    console.log('edit camp');
    this.campaign = R.clone(this.campaignService.selectedCampaign);

    if (!this.campaign || this.campaign.id === 0) {
      this.router.navigate(['/']);
      return;
    }

    if (paramId === 'copy') {

      // duplicate/create a new campaign from selectedCampaign
      this.campaign.id = 0;
      this.campaign.name += '-copie';
      // tslint:disable-next-line:triple-equals
      if (this.campaign.seq_type == 1) {
        this.campaign.asap = 2;
      }

    } else {
      if (paramId !== this.campaign.id) {
        this.toastr.error('Les identifiants de campagne ne correspondent pas !!', '');
        this.router.navigate(['/']);
        return;
      }
    }

    this.activeTab = 0;
    this.activeTab2 = 0;

    this.campaign.startdate = new Date(this.campaign.datestart);
    this.campaign.stopdate = new Date(this.campaign.datestop);
    this.campaign.starttime = new Date(this.campaign.datestart);
    this.campaign.stoptime = new Date(this.campaign.datestop);

    // reset selected flags on lists
    this.lists.forEach(l => l.selected = 0);

    // set selected only for lists presents in campaign.lists
    this.campaign.lists.forEach((list) => {
      this.lists.forEach(l => {
        if (l.id === list.listid) {
          l.selected = 1;
        }
      });
    });
    this.fields = this.campaignService.intersectFields(this.campaign, this.lists);

    if (this.campaign.priority == 2) {
      this.computeTotalContact();
    }

    this.SchedCheck();

    this.computeMaxTab();
    if (this.campaign.seq_nb_per_period >= 1) {
      this.updateSeqNbPerPeriod();
    } else {
      this.updateSeqDelay();
    }
    this.computeAvailableHours();

    this.computeMsgLen();
  }

  computeTotalContact() {
    // used for nolist campaign
    const contact = this.campaign.destination_list.split(';');
    this.campaign.total_contact = contact.length;
  }

  updateSeqNbPerPeriod() {

    console.log('updateSeqNbPerPeriod');

    // compute duration from nb_per_period
    const seqDuration = this.campaign.total_contact / parseFloat(this.campaign.seq_nb_per_period);
    if (!isNaN(seqDuration)) {
      this.campaign.seq_hduration = (seqDuration / 60).toFixed(2);
      this.campaign.seq_duration = Math.floor(seqDuration / 60) + 'h ' + Math.ceil(seqDuration % 60) + 'm';
    } else {
      this.campaign.seq_hduration = 0;
      this.campaign.seq_duration = '';
    }

    // compute delay from nb_per_period
    let seqDelay = '';
    if (this.campaign.seq_nb_per_period >= 1) {
      seqDelay = (1 / this.campaign.seq_nb_per_period).toFixed(2);
    }
    this.campaign.seq_delay = seqDelay;
  }

  updateSeqDelay() {
    // compute duration from delay
    const seqDuration = this.campaign.total_contact * parseFloat(this.campaign.seq_delay);  // in minutes
    if (!isNaN(seqDuration)) {
      this.campaign.seq_hduration = (seqDuration / 60).toFixed(2);
      this.campaign.seq_duration = Math.floor(seqDuration / 60) + 'h ' + Math.ceil(seqDuration % 60) + 'm';
    } else {
      this.campaign.seq_duration = '';
      this.campaign.seq_hduration = 0;
    }

    // compute nbperperiod from delay
    let seqNbPerPeriod = '';
    if (this.campaign.seq_delay >= 1) {
      seqNbPerPeriod = (1 / this.campaign.seq_delay).toFixed(2);
    }
    this.campaign.seq_nb_per_period = seqNbPerPeriod;
  }

  updateSeqDuration() {
    // compute nb_per_period from duration
    const nbPerPeriod = this.campaign.total_contact / 60.0 / parseFloat(this.campaign.seq_duration);
    this.campaign.seq_nb_per_period = Math.ceil(nbPerPeriod);

    this.campaign.seq_one_every = 0;
    if (nbPerPeriod <= 1.0) {
      this.campaign.seq_one_every = Math.ceil(parseFloat(this.campaign.seq_duration) * 60.0 / this.campaign.total_contact);
    }
  }

  SchedSelectDay(day) {
    console.log('selectday');
    // tslint:disable-next-line:triple-equals
    day.checked = day.checked == '1' ? '0' : '1';
    this.computeAvailableHours();
  }

  SchedSelectHour($event, day, hour) {

    // tslint:disable-next-line:triple-equals
    if (hour.checked == '1') {
      hour.checked = '0';
      if ($event.shiftKey) {
        // tslint:disable-next-line:no-shadowed-variable
        for (let h = parseInt(hour.label, 10) - 1; h >= 0; h--) {
          // tslint:disable-next-line:triple-equals
          if (day.timerange[h].checked == '1') {
            day.timerange[h].checked = '0';
          } else {
            break;
          }
        }
      }
    } else {
      hour.checked = '1';
      if ($event.shiftKey) {
        // tslint:disable-next-line:no-shadowed-variable
        for (let h = parseInt(hour.label, 10) - 1; h >= 0; h--) {
          // tslint:disable-next-line:triple-equals
          if (day.timerange[h].checked == '0') {
            day.timerange[h].checked = '1';
          } else {
            break;
          }
        }
      }
    }

    this.computeAvailableHours();
  }



  daysBetween(startDate, endDate) {
    const duration = moment.duration(endDate.diff(startDate));
    return duration.days();
  }

  getDay(delta) {
    const today = moment();
    const next = today.add(delta, 'days');
    return moment(next).format('YYYY-MM-DD');
  }


  SchedCheck() {
    this.campaign.schedule.forEach(day => {
      const ddate = new Date(day.date);
      const now = new Date(Date.now());
      day.delete = 0;
      if (this.daysBetween(now, ddate) < -1) {
        day.delete = 1;
      }
    });

    const newsched = [];
    for (const s of this.campaign.schedule) {
      if (s.delete === 0) {
        newsched.push(s);
      }
    }
    this.campaign.schedule = newsched;

    if (this.campaign.schedule.length < 7) {
      for (let d = this.campaign.schedule.length; d < 7; d++) {
        const date = this.getDay(d);
        const mdate = moment(date).locale('fr');
        const day = { checked: '1', date, label: mdate.format('dddd DD MMM'), available: 0, timerange: R.clone(this.defaultTimerange) };
        this.computeAvailableForDay(day);
        this.campaign.schedule.push(day);
      }
    }
  }

  selectSeqTab() {
    console.log('select SeqTab');
    this.computeMaxTab();
    setTimeout(() => {
      this.activeTab = this.maxTab;
      this.activeTab2 = this.maxTab2;
    }, 10);
  }


  computeMaxTab() {
    if (this.campaign.asap === 2) {
      this.maxTab = 3;
      this.maxTab2 = 1;  // no seq in immediate
      this.campaign.seq_type = 1;
    } else {
      this.maxTab = 2;
      this.maxTab2 = 1;
      this.campaign.seq_type = 0;
    }
    console.log('maxTab=' + this.maxTab);
  }

  nextTab() {
    this.activeTab++;
    if (this.activeTab > this.maxTab) {
      this.activeTab = this.maxTab;
    }
  }
  prevTab() {
    this.activeTab--;
    if (this.activeTab < 0) {
      this.activeTab = 0;
    }
  }
  nextTab2() {
    this.activeTab++;
    if (this.activeTab > this.maxTab) {
      this.activeTab = this.maxTab;
    }
  }
  prevTab2() {
    this.activeTab--;
    if (this.activeTab < 0) {
      this.activeTab = 0;
    }
  }


  selectTab(tab) {
    this.activeTab = tab;
  }

  computeMsgLen() {
    if (!this.campaign || !this.campaign.message) {
      this.computed = { long: 0, sms: 0 };
      return 0;
    }
    this.campaign.message = this.campaign.message.replace(/…/g, '...');
    this.campaign.message = this.campaign.message.replace(/`/g, '\'');
    this.campaign.message = this.campaign.message.replace(/’/g, '\'');
    this.campaign.message = this.campaign.message.replace(/«/g, '"');
    this.campaign.message = this.campaign.message.replace(/»/g, '"');
    const len = this.campaign.message.replace(/([{}\[\]\\|\^~€])/g, '\\$1').replace(/\n/g, '-').length;
    let sms = 1;
    if (len > 160) { sms = Math.ceil(len / 153); }
    // tslint:disable-next-line:object-literal-shorthand
    this.computed = { long: len, sms: sms };
  }

  filterChange() {
    console.log('filter contacts in list', this.filter);
  }
  listSelect(list) {
    list.selected = 1;
    this.fields = this.campaignService.intersectFields(this.campaign, this.lists);
  }
  listUnselect(list) {
    list.selected = 0;
    this.fields = this.campaignService.intersectFields(this.campaign, this.lists);
  }
  selectActif(event, list) {
    event.stopPropagation();
    console.log(list.id);
    // get contact list
    this.contactListService.ContactGetAll(this.client.id, list.id)
      .subscribe((data: any[]) => {
        console.log('contact get', data);
        console.log('list', list);
        this.list.id = list.id;
        this.list.fields = list.fields;
        this.list.count = list.count;
        this.list.actif = list.actif;
        this.list.contacts = data;
        this.list.visible = R.repeat(true, data.length);
        this.showListFilterModal = true;
      });
  }

  ContactClearAll() {
    this.list.actif = 0;
    this.list.contacts.forEach(c => c.selected = 0);
  }

  ContactSelectAll() {
    this.list.actif = this.list.contacts.length;
    this.list.contacts.forEach(c => c.selected = 1);
  }

  ContactSelect(index) {
    this.list.actif++;
    this.list.contacts[index].selected = 1;
  }

  ContactClear(index) {
    this.list.actif--;
    this.list.contacts[index].selected = 0;
  }

  applyFilter() {
    this.showListFilterModal = false;
    this.isSpinnerVisible = true;
    // update selected list
    const selected = this.lists.find(l => l.id === this.list.id);
    if (selected) {
      selected.actif = this.list.actif;
      selected.contacts = R.clone(this.list.contacts);
    }
    // update all contacts selected flag
    this.contactListService.UpdateListAndContacts(this.client.id, this.list)
      .subscribe((response) => {
        if (response.success) {
          this.toastr.success('Votre séléction a été sauvegardée', '');
        } else {
          this.toastr.error(response.message, 'Code promo invalide', { timeOut: 0 });
        }
        this.isSpinnerVisible = false;
      });
  }



  fillWithModele() {
    // console.log('modele change', this.modele);
    this.campaign.message = R.clone(this.modele.message);
    this.computeMsgLen();
  }

  addModele() {
    this.showAddModele = true;
  }

  storeAsModele() {
    this.showAddModele = false;
    this.isSpinnerVisible = true;
    this.modele = { id: 0, label: R.clone(this.modeleLabel), message: R.clone(this.campaign.message), islast: 0 };
    this.userService.AddModele(this.client.id, this.modele)
      .subscribe((response: any) => {
        if (response.success) {
          this.toastr.success('Le modèle \'' + this.modele.label + '\' a été ajouté', '');
          this.loadModeles();
        } else {
          this.toastr.error(response.message, '', { timeOut: 0 });
        }
        this.isSpinnerVisible = false;
      });
  }

  addMsgField() {
    this.campaign.message += ' %' + this.fieldToAdd + '%';
    this.computeMsgLen();
  }

  addMsgStop() {
    this.campaign.message = this.campaign.message.replace(/[\n]?STOP au [0-9]+/, '');
    this.campaign.message += '\nSTOP au ' + (this.stops[this.campaign.priority]);
    this.computeMsgLen();
  }

  addMsgReponse() {
    this.campaign.message = this.campaign.message.replace(/[\n]?REPONSE au [0-9]+/, '');
    if (this.campaign.priority >= 1) {
      this.campaign.message += '\nREPONSE au ' + (this.stops[this.campaign.priority]);
    }
    this.computeMsgLen();
  }

  addShortUrl() {
    this.campaign.message += ' http://isisms.fr/%SURL%';
    this.computeMsgLen();
  }

  yyyymmdd(date) {
    const yyyy = date.getFullYear().toString();
    const mm = (date.getMonth() + 1).toString(); // getMonth() is
    // zero-based
    const dd = date.getDate().toString();
    return yyyy + '-' + (mm[1] ? mm : '0' + mm[0]) + '-' + (dd[1] ? dd : '0' + dd[0]); // padding
  }

  hhmm(date) {
    if (typeof date === 'string') {
      if (date.length === 8) {
        return date;
      } else {
        return date.substr(11, 8);
      }
    }
    const hh = date.getHours().toString();
    const mm = date.getMinutes().toString();
    return (hh[1] ? hh : '0' + hh[0]) + ':' + (mm[1] ? mm : '0' + mm[0]) + ':00'; // padding
  }


  updateCampaign() {

    console.log('updateCampaign');

    this.isSpinnerVisible = true;

    // merge date and time
    this.campaign.datestart = this.yyyymmdd(this.campaign.startdate) + ' ' + this.hhmm(this.campaign.starttime);
    this.campaign.datestop = this.yyyymmdd(this.campaign.startdate) + ' ' + this.hhmm(this.campaign.stoptime);    // /!\ use startdate here , it must be same day


    const now = new Date();
    const nowymdhhmm = this.yyyymmdd(now) + ' ' + this.hhmm(now);
    // console.log(nowymdhhmm , this.campaign.datestart, +this.campaign.asap === 0 ? 'prog' : 'imme');
    if (+this.campaign.asap === 0 && nowymdhhmm > this.campaign.datestart) {
      this.toastr.error('La date de début ne peut pas être dans le passé', '', { timeOut: 3000 });
      this.isSpinnerVisible = false; // Show
      return;
    }


    // const diff = stop.diff(start, 'minutes');
    // if (diff <60) {
    //  alert('Attention il faut au moins 1 heure entre le début et la fin de la campagne');
    //  return;
    // }


    // concat fields
    this.campaign.fields = JSON.stringify(this.fields);

    if (this.campaign.priority == 0 && this.campaign.message.length > 160) {
      // truncate to 160 char if low-cost
      this.campaign.message = this.campaign.message.substring(0, 160);
      this.campaign.messagelen = 160;
    } else {
      this.campaign.messagelen = this.campaign.message.length;
    }
    if (this.campaign.priority == 2) {
      // campagne sans liste -> total=combien de destinataires
      this.campaign.total = this.campaign.destination_list.split(';').length;
    } else {
      this.campaign.total = 0;
    }

    this.computeAvailableHours();

    if (this.campaign.id === 0) {
      this.campaignService.Add(this.client.id, this.campaign).subscribe((response: any) => {
        if (response.success) {
          this.toastr.success('La campagne a été créée', '');
          this.campaign.id = response.id;
          // alert(this.campaign.id);
          this.AddContactList();

          this.modele = { id: 0, label: 'Dernier message créé', message: R.clone(this.campaign.message), islast: 1 };
          this.userService.AddModele(this.client.id, this.modele).subscribe(data => {
            this.loadModeles();
          });

          // jump to campaign "en preparation"
          this.router.navigate(['/campagne/status/preparation']);

        } else {
          this.toastr.error(response.message, '', { timeOut: 0 });
        }
        this.isSpinnerVisible = false;
      });
    } else {
      this.campaignService.Update(this.client.id, this.campaign).subscribe((response: any) => {
        if (response.success) {
          this.toastr.success('La campagne a été modifiée', '');
          this.client.email_invalid = 0;
          this.AddContactList();
          // tslint:disable-next-line:triple-equals
          if (this.campaign.status == 0) {
            this.router.navigate(['/campagne/status/preparation']);
            // tslint:disable-next-line:triple-equals
          } else if (this.campaign.status == 1) {
            this.router.navigate(['/campagne/status/encours']);
          } else {
            this.router.navigate(['/campagne/status/archivee']);
          }

          this.modele = { id: 0, label: 'Dernier message créé', message: R.clone(this.campaign.message), islast: 1 };
          this.userService.AddModele(this.client.id, this.modele).subscribe(data => {
            this.loadModeles();
          });
        } else {
          this.toastr.error(response.message, '', { timeOut: 0 });
        }
      });
    }
  }

  AddContactList() {
    const listids = [];
    this.lists.forEach(list => {
      if (list.selected === 1) {
        listids.push(list.id);
      }
    });

    if (listids.length > 0) {
      this.campaignService.AddList(this.client.id, this.campaign.id, listids)
        .subscribe((response: any) => {
          if (response.success) {
            if (listids.length === 1) {
              this.toastr.success('1 liste ajoutée', '');
            } else {
              this.toastr.success(listids.length + ' listes ajoutées', '');
            }
          }
        });
    } else {
      if (this.campaign.priority <= 1) {
        this.toastr.warning('Attention, vous n\'avez pas sélectionné de listes de contacts', '');
      }
    }
  }

  SchedAddDay() {
    // get date from schedNewDay
    const date = moment(this.schedNewDay).format('YYYY-MM-DD');
    const dayS = moment(this.schedNewDay).locale('fr');

    const day = { checked: '1', date, label: moment(dayS).format('dddd DD MMM'), available: 0, timerange: R.clone(this.defaultTimerange) };
    this.computeAvailableForDay(day);
    this.campaign.schedule.push(day);
  }

  RemoveDay(day) {
    for (const index in this.campaign.schedule) {
      if (this.campaign.schedule[index].date === day.date) {
        this.campaign.schedule.splice(index, 1);
      }
    }
  }

  computeAvailableHours() {

    // recompute available hours for campaign
    let seqAvailable = 0;
    this.campaign.schedule.forEach(d => {
      this.computeAvailableForDay(d);
      // tslint:disable-next-line:triple-equals
      if (d.checked == '1') {
        seqAvailable += parseInt(d.available, 10);
      }
    });
    this.campaign.seq_available = seqAvailable;
  }

  computeAvailableForDay(day) {
    const now = moment();
    // recompute available hours for day
    let available = 0;
    // tslint:disable-next-line:triple-equals
    if (day.checked == '1') {
      for (let h = 0; h < 24; h++) {
        const date = moment(day.date + ' ' + (h + 1) + ':00:00');
        if (date.isAfter(now)) {
          // console.log(h + ' isafter');
          // tslint:disable-next-line:triple-equals
          if (day.timerange[h].checked == '1') {
            available++;
          }
        } else {
          // tslint:disable-next-line:triple-equals
          if (day.timerange[h].checked == '1') {
            day.timerange[h].checked = '2';
          }
          // console.log(h + ' isbefore');
        }
      }
    }
    day.available = available;
  }

  priorityChange() {
    if (this.campaign.message.indexOf('STOP au') !== -1) {
      this.addMsgStop();
    }
    if (this.campaign.message.indexOf('REPONSE au') !== -1) {
      this.addMsgReponse();
    }
  }

  uploadList() {
    this.router.navigate(['/contact/charger']);
  }


}

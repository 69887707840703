import { Component, OnInit, ViewChildren, QueryList, ViewChild, ElementRef, AfterViewChecked, AfterViewInit, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, of } from 'rxjs';
import { SortEvent, NgbdSortableHeader } from 'src/app/directives/sortable.directive';
import { environment } from '../../../environments/environment';

import { PagingService } from '../../providers/paging.service';
import { UserService } from 'src/app/providers/user.service';
import { Router } from '@angular/router';

import * as R from 'ramda';
import { ExportDataService } from 'src/app/providers/export-data.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NgbHighlight, NgbModal, NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { IUser } from 'src/app/interfaces/user';
import { ContactListService } from 'src/app/providers/contact-list.service';
import { IContactList, ContactList, IContact } from 'src/app/interfaces/contact-list';
import { AsyncPipe } from '@angular/common';
import { SpinnerComponent } from '../spinner/spinner.component';
import { ClientSelectComponent } from '../client-select/client-select.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ModalComponent } from 'src/app/modals/modal.component';

@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss'],
  standalone: true,
  imports: [SpinnerComponent, ClientSelectComponent, FormsModule, NgbdSortableHeader, CommonModule, NgbHighlight, NgbPagination, ModalComponent, AsyncPipe]
})

export class ContactListComponent implements OnInit, OnDestroy {

  @ViewChild('longurl', {static: true}) longUrl: ElementRef;

  clist$: Observable<IContactList[]>;
  contacts: Observable<IContact>[];
  total$: Observable<number>;
  loading$: Observable<boolean>;

  selectedCList: IContactList = new ContactList(0);

  page = 1;
  pageSize = 10;
  collectionSize = 1;
  searchFields = ['id', 'name', 'datecreation', 'count', 'actif'];

  showDeleteConfirm = false;
  showAddModele = false;
  showRename = false;
  showInsertUrl = false;
  initialized = false;
  isSpinnerVisible = false;
  fields = [];
  fieldSelection = [];

  activeTab: 'lists' | 'contacts' = 'lists';

  client: IUser;
  clientSubscribe = null;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(
    public pagingService: PagingService<IContactList>,
    private userService: UserService,
    private exportDataService: ExportDataService,
    private contactListService: ContactListService,
    private toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal,
  ) {
    this.clist$ = pagingService.rowsObs;
    this.total$ = pagingService.totalObs;
    this.loading$ = pagingService.loadingObs;
    this.pagingService.searchTerm = '';
    // set fields for filtering
    this.pagingService.fields = this.searchFields;
  }

  ngOnInit(): void {

    this.pagingService.loadRows(of([]));

    this.clientSubscribe = this.userService.selectedUser$.subscribe(user => {
      if (user) {
        this.client = user;
        if (this.client) {
          this.pagingService.loadRows(this.contactListService.GetAll(this.client.id));
          this.loading$.subscribe(loading => {
            if (loading) {
              this.isSpinnerVisible = true;
            } else {
              this.isSpinnerVisible = false;
              this.initialized = true;
            }
          });
        }
      }
    });
    setTimeout(() => {
      if (!this.client) {
        this.toastr.warning('Sélectionner un client en premier', '');
        this.router.navigate(['/']);
      }
    }, 1000);
    this.pagingService.page = 1;
  }

  ngOnDestroy() {
    console.log('campaign list onDestroy');
    if (this.clientSubscribe) {
      this.clientSubscribe.unsubscribe();
    }
  }

  pageChanged(event) {
    console.log('onPageChange', event);
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.pagingService.sortColumn = column;
    this.pagingService.sortDirection = direction;
  }

  selectContactlist(list) {
    this.selectedCList = R.clone(list);
  }

  Rename(list) {
    this.selectedCList = R.clone(list);
    this.showRename = true;
  }

  // RenameConfirm() {
  //   this.spinner.show();
  //   if (this.selectedCList.id > 0) {
  //     this.contactListService.Update(this.client.id, this.selectedCList)
  //       .subscribe((response: any) => {
  //         if (response.success) {
  //           this.toastr.success('La liste \'' + this.selectedCList.name + '\' a été modifiée');
  //           this.pagingService.loadRows(this.contactListService.GetAll(this.client.id));
  //         } else {
  //           this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
  //         }
  //         this.spinner.hide();
  //       });
  //   }
  //   this.showRename = false;
  // }

  InsertUrl(list) {
    console.log('insertUrl');
    this.selectedCList = R.clone(list);

    this.fields = JSON.parse(this.selectedCList.fields
                    .replace(',,', ',')
                    .replace('[,', '[')
                    .replace(',]', ']'));
    if (this.selectedCList.longurl === '' && this.client.company === 'MLP') {
      setTimeout(() => {
        // force refresh after cycle
        this.selectedCList.longurl = 'https:/isisms.fr/mlp/photo';
        // ?telephone=%TELEPHONE%&nim=%NIM%";
      }, 0);
    }

    this.fieldSelection = [];
    for (const f of this.fields) {
        if (this.selectedCList.longurl.indexOf(f) >= 0) {
          this.fieldSelection.push(1);
        } else {
          this.fieldSelection.push(0);
        }
    }
    this.showInsertUrl = true;
    if (this.longUrl) {
      setTimeout(() => {
        this.longUrl.nativeElement.focus();
      }, 100);
    }
  }

  fieldClick() {
    let first = true;
    this.selectedCList.longurl = this.selectedCList.longurl.split('?')[0];
    // tslint:disable-next-line:no-shadowed-variable
    for (let index = 0; index < this.fields.length; index++) {
      if (this.fieldSelection[index] === 1) {
        this.selectedCList.longurl += (first ? '?' : '&');
        first = false;
        this.selectedCList.longurl += this.fields[index].toLowerCase() + '=%' + this.fields[index].toUpperCase() + '%';
      }
      console.log(this.selectedCList.longurl);
    }
  }

  displayModifiedResult(response) {
    if (response.success) {
      this.toastr.success('La liste \'' + this.selectedCList.name + '\' a été modifiée');
      this.pagingService.loadRows(this.contactListService.GetAll(this.client.id));
    } else {
      this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
    }
    this.isSpinnerVisible = false;
  }

  UpdateCList() {
    this.isSpinnerVisible = true;
    if (this.selectedCList.id > 0) {
      this.contactListService.Update(this.client.id, this.selectedCList)
        .subscribe((response: any) => {
          this.displayModifiedResult(response);
        });
    }
    this.showRename = false;
  }

  UpdateCListLongUrl() {
    this.isSpinnerVisible = true;
    if (this.selectedCList.id > 0) {
      this.contactListService.SetUrl(this.client.id, this.selectedCList)
        .subscribe((response: any) => {
          this.displayModifiedResult(response);
        });
    }
    this.showInsertUrl = false;
  }

  Export(kind) {
    console.log('export', kind);
    const records = [];
    const fields = ['id', 'label', 'message', 'longueur', 'sms'];
    for (const u of this.pagingService.filteredRows) {
      const model = R.pick(fields, u);
      records.push(model);
    }
    const header = {};
    fields.forEach(f => header[f] = f.replace(/[^a-z0-9]/gmi, '').toUpperCase());
    records.unshift(header);
    this.exportDataService.exportAs(kind, records, 'user');
  }

  Delete(list) {
    this.selectedCList = list;
    this.showDeleteConfirm = true;
    console.log('show delete confirm');
  }

  DeleteConfirm() {
    this.showDeleteConfirm = false;
    console.log('call delete api');
    this.isSpinnerVisible = true;

    this.contactListService.Delete(this.client.id, this.selectedCList.id).subscribe((response: any) => {
      if (response.success) {
        this.toastr.success('La liste a été supprimée', '');
        this.pagingService.loadRows(this.contactListService.GetAll(this.client.id));
      } else {
        this.toastr.error(response.message, 'Erreur', { timeOut: 0 });
      }
      this.isSpinnerVisible = false;
    });
  }

  Contact(list) {
    this.selectedCList = list;
    this.contactListService.selectedCList = list;
    this.router.navigate(['/contact/affichage']);
  }

  uploadList() {
    this.router.navigate(['/contact/charger']);
  }

  closeImport() {
    console.log('import closed');
  }

  CreateCampaign(list) {
    console.log('list=', list);
    this.contactListService.selectedCList = list;
    this.router.navigate(['/campagne/creation/1']);
  }

  AddContact(list) {
    this.contactListService.selectedCList = list;
    this.router.navigate(['/contact/ajouter']);
  }

}

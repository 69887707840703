<div class="topbar">
  <nav class="w-100 navbar navbar-mycustom">

    <div class="text-center nav-item" style="margin-top:11px; margin-right: 40px" [routerLink]="['']">
        <a class="navbar-brand" href="#/">
          <img [src]="siteicon" />
        </a>
        <!-- <h3 style="margin-top: -5px; font-size: 20px">{{siteurl}}</h3> -->
    </div>
    <div *ngIf="isAdmin" class="d-sm-block d-none nav-item" style="margin-top: 10px" [routerLink]="['/client/selection']" title="Sélection client">
      <button class="open-left button-menu-mobile">
        <i class="zmdi zmdi-accounts-outline" style="font-size: 36px"></i>
      </button>
    </div>
    <div class="d-sm-block d-none nav-item" style="margin-top: 10px" [routerLink]="['/contact/list']" title="Listes de contacts">
      <button class="open-left button-menu-mobile">
        <i class="zmdi zmdi-accounts-add" style="font-size: 36px"></i>
      </button>
    </div>
    <div class="d-sm-block d-none nav-item" style="margin-top: 9px">
      <button class="open-left button-menu-mobile" [routerLink]="['/campagne/creation/1']" title="Créer une campagne">
        <i class="zmdi zmdi-comment-text" style="font-size: 26px"></i>
      </button>
    </div>
    <div class="d-sm-block d-none nav-item" style="margin-top: 9px">
      <button class="open-left button-menu-mobile" [routerLink]="['/campagne/status/encours']" title="Campagnes en cours">
        <i class="zmdi zmdi-traffic" style="font-size: 30px"></i>
      </button>
    </div>
    <div class="d-sm-block d-none nav-item" style="margin-top: 9px">
      <button class="open-left button-menu-mobile" [routerLink]="['/client/edition']" title="Edition client">
        <i class="zmdi zmdi-odnoklassniki" style="font-size: 30px"></i>
      </button>
    </div>
    <div class="d-sm-block d-none nav-item" style="margin-top: 9px">
      <button class="open-left button-menu-mobile" [routerLink]="['/client/credit']" title="Crédit client">
        <i class="zmdi zmdi-info" style="font-size: 30px"></i>
      </button>
    </div>
    <div *ngIf="!isAdmin" class="d-sm-block d-none nav-item" style="margin-top: 9px">
      <button class="open-left button-menu-mobile" [routerLink]="['/nous_contacter']" title="Nous contacter">
        <i class="zmdi zmdi-email" style="font-size: 30px"></i>
      </button>
    </div>

    <div class="d-block d-sm-none nav-item" style="margin-top: 9px; margin-left: 10px" [routerLink]="['']" title="menu">
      <button class="open-left button-menu-mobile">
        <i class="fa fa-2x fa-home"></i>
      </button>
    </div>

    <div class="nav-item" style="margin-top: 9px; margin-left: auto">
      <button class="open-left button-menu-mobile" (click)="logout()" title="Se déconnecter">
        <i class="zmdi zmdi-power" style="font-size: 30px"></i>
      </button>
    </div>
    <div *ngIf="watchdog?.actif" class="nav-item" style="margin-top: 9px; margin-left: 100px; color:orange">
      Déconnexion dans {{watchdog.countdown}} sec !
    </div>

    <div class="clearfix"></div>
    <div class="text-center" *ngIf="client?.disabled==1 && !isAdmin">
      <div class="text-warning blink suspendu text">Votre compte est suspendu !!</div>
    </div>
    <div class="text-center" *ngIf="client?.disabled==2 && !isAdmin">
      <div class="text-warning blink suspendu text">Votre compte est en attente de validation !!</div>
    </div>
  </nav>
</div>


<app-modal [(isActive)]="showLogoutConfirm" [overlayDismiss]="true" (overlayClick)="showLogoutConfirm=false">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Déconnexion ? </h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showLogoutConfirm=false">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-dismiss="modal"
      (click)="showLogoutConfirm=false">Annuler</button>
      <button type="button" class="btn btn-primary" (click)='logoutConfirm()'>Valider</button>
    </div>
  </div>
</app-modal>

<app-spinner [visible]="isSpinnerVisible"></app-spinner>
<div class="p-20">
  <div class="clearfix">
    <form class="inline">
      <button type="button" (click)="Export('TEXT')" class="ml-2 h-100 btn btn-outline-secondary btn-sm">Copy</button>
      <button type="button" (click)="Export('CSV')" class="ml-2 h-100 btn btn-outline-secondary btn-sm">CSV</button>
      <button type="button" (click)="Export('EXCEL')" class="ml-2 h-100 btn btn-outline-secondary btn-sm">Excel</button>
      <button type="button" (click)="Export('PRINT')" class="ml-2 h-100 btn btn-outline-secondary btn-sm">Print</button>
    </form>
    <br>

    <table id="selectedColumn" class="table table-hover table-sm table-striped" cellspacing="0"
      width="100%">
      <thead>
        <tr>
          <th class="th">Mois</th>
          <th class="th">Total consommés</th>
          <th class="th">Total facturable</th>
          <th class="th">Premium ajoutés</th>
          <th class="th">Lowcost ajoutés</th>
          <th class="th">Forfait</th>
          <th class="th">Postpaid</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let c of conso">
          <th>{{c.date}}</th>
          <td>{{-1 * c.premiumcons}}
            <span *ngIf='c.lowcostcons<0'> / {{-1 * c.lowcostcons}}</span>
          </td>
          <th>{{c.total}}</th>
          <td>{{c.premium}}</td>
          <td>{{c.lowcost}}</td>
          <td>{{c.forfait}} <span *ngIf='c.forfaitC>0'>({{c.forfaitC}})</span></td>
          <td>{{c.postpaid}} <span *ngIf='c.postpaidC>0'>({{c.postpaidC}})</span></td>
        </tr>
      </tbody>
    </table>

  </div>
</div>

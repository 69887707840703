@if (authService.isAdmin) {
<div class="select-user alert alert-secondary">

    <div class="pull-right btn btn-info"  style="margin-top:-9px" [routerLink]="['/client/selection']">Selectionner</div>
    @if (!selectedUser) {
    <div>Sélectionnez un client ... </div>
    } @else {
        <div>
            Client sélectionné: <b>{{selectedUser.company}} {{selectedUser.firstname}} {{selectedUser.name}}</b> ({{selectedUser.id}})

            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Crédits SMS: Premium <b>{{selectedUser.premium}}</b>
        </div>
    }

</div>
}

<div class="p-md-4 client-view">

  <app-spinner [visible]="isSpinnerVisible"></app-spinner>

  <app-client-select [selectedUser]="client"></app-client-select>

  <div class="card">
    <div class="card-body">

      <h3>Liste de téléphones blacklistés</h3>

      <br>
      <br>

      <form class="form-inline p-3">
        <div class="form-row">
          <div class="form-group">
            <label>
              <input class="form-control" type="text" name="id" [(ngModel)]="mobile" value=""
                placeholder="Entrer un numéro ...">
            </label>
            <button class="btn btn-success" (click)="addMobile()">
              <i class="fa fa-plus"></i></button>
          </div>
        </div>
      </form>
      <br>
      <br>

      <div class="clearfix">
        <form class="inline">
          <button type="button" (click)="Export('TEXT')"
            class="ml-2 h-100 btn btn-outline-secondary btn-sm">Copy</button>
          <button type="button" (click)="Export('CSV')" class="ml-2 h-100 btn btn-outline-secondary btn-sm">CSV</button>
          <button type="button" (click)="Export('EXCEL')"
            class="ml-2 h-100 btn btn-outline-secondary btn-sm">Excel</button>
          <button type="button" (click)="Export('PRINT')"
            class="ml-2 h-100 btn btn-outline-secondary btn-sm">Print</button>

          <div class="form-inline ml-auto form-group">
            Recherche: <input class="form-control ml-2" type="text" name="searchTerm"
              [(ngModel)]="pagingService.searchTerm" />
          </div>
        </form>

        <table id="selectedColumn" class="table-bordered table table-hover table-sm table-striped" cellspacing="0"
          width="100%">
          <thead>
            <tr>
              <th class="th" sortable="id" (sort)="onSort($event)">ID</th>
              <th class="th" sortable="mobile" (sort)="onSort($event)">Mobile</th>
              <th class="th" sortable="date_added" (sort)="onSort($event)">Date d'ajout</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          @if (initialized) {
          <tbody>
            @for (blacklist of blacklist$ | async; track blacklist.id) {
            <tr (click)="selectMobile(blacklist)" [ngClass]="{'selected': blacklist.id === selectedBlacklist?.id}">
              <td>{{blacklist.id}}</td>
              <td>
                <ngb-highlight [result]="blacklist.mobile" [term]="pagingService.searchTerm"></ngb-highlight>
              </td>
              <td>
                <ngb-highlight [result]="blacklist.date_added" [term]="pagingService.searchTerm"></ngb-highlight>
              </td>
              <td>
                <div class="inline">
                  <button type="button" title="Supprimer" (click)="Delete(blacklist)"
                    class="ml-1 btn btn-danger btn-sm w30"><i class="fa-trash-o fa"></i></button>
                </div>
              </td>
            </tr>
            }
          </tbody>
          }
        </table>

        <div class="inline">
          <div class="">
            Total: {{(total$ | async)}}
          </div>
          <div class="ml-auto">
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="total$ | async"
              [(page)]="pagingService.page" [boundaryLinks]="true" [rotate]="true" [directionLinks]="true"
              [pageSize]="pagingService.pageSize" [maxSize]="3" name="page">
            </ngb-pagination>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>


<app-modal [(isActive)]="showDeleteConfirm" [overlayDismiss]="true" (overlayClick)="showDeleteConfirm=false">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Restaurer un numéro</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="showDeleteConfirm=false">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Le numéro {{selectedBlacklist?.mobile}} pourra de nouveau recevoir les SMS</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)='DeleteConfirm()'>Confirmer</button>
      <button type="button" class="btn btn-secondary" data-dismiss="modal"
        (click)="showDeleteConfirm=false">Annuler</button>
    </div>
  </div>
</app-modal>
